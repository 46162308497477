import { Fragment } from "react";
import { RightArrow } from "../AllSvgs";

// heading tag
export const H1Tag = ({ classes, title }) => {
  return <h1 className={`heading__h1 ${classes}`}>{title}</h1>;
};

export const H2Tag = ({ classes, title }) => {
  return <h2 className={`heading__h2 ${classes}`}>{title}</h2>;
};

export const H3Tag = ({ classes, title }) => {
  return <h3 className={`heading__h3 ${classes}`}>{title}</h3>;
};

export const H4Tag = ({ classes, title }) => {
  return <h4 className={`heading__h4 ${classes}`}>{title}</h4>;
};

export const H5Tag = ({ classes, title }) => {
  return <h5 className={`heading__h5 ${classes}`}>{title}</h5>;
};

export const H6Tag = ({ classes, title }) => {
  return <h6 className={`heading__h6 ${classes}`}>{title}</h6>;
};

// paragraph tag (p)
export const PTag = ({ value, classes }) => {
  return <p className={`value ${classes}`}>{value}</p>;
};
export const TitleTag = ({ value, classes }) => {
  return <p className={`value text-2xl text-gray-600 ${classes}`}>{value}</p>;
};
export const DiscriptionTag = ({ value, classes }) => {
  return <p className={`value text-md ${classes}`}>{value}</p>;
};

// anchor tag (a)
export const ATag = ({ classes, src, children, ...rest }) => {
  return (
    <a href={src} className={`${classes}`} {...rest}>
      {children}
    </a>
  );
};

// list tag
export const ULTag = ({ classes, children }) => {
  return <ul className={`unorderedList  ${classes}`}>{children}</ul>;
};

export const LITag = ({ children, classes }) => {
  return <li className={`listItem ${classes}`}>{children}</li>;
};

// input
export const InputTag = ({ classes, placeholder, type, ...rest }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className={`input ${classes}`}
      {...rest}
    />
  );
};

// textarea
export const MultiLineInputTag = ({ classes, placeholder, ...rest }) => {
  return (
    <textarea
      className={`form-control ${classes}`}
      placeholder={placeholder}
      rows="4"
      {...rest}
    ></textarea>
  );
};

// button
export const ButtonTag = ({ classes, value, ...rest }) => {
  return (
    <button className={`btn ${classes}`} {...rest}>
      {value}
    </button>
  );
};

// img tag
export const ImgTag = ({ src, alt, classes, ...rest }) => {
  return <img src={src} className={classes} alt={alt} {...rest} />;
};

// em tag
export const Emtag = ({ classes, text }) => {
  return <em className={classes}>{text}</em>;
};

// label tag
export const LabelTag = ({ For, classes, text }) => {
  return (
    <label htmlFor={For} className={classes}>
      {text}
    </label>
  );
};

// back button
export const BackButton = ({ src, title, ...rest }) => {
  return (
    <ATag
      src={src}
      classes={"d-flex align-items-center pointer mb-3"}
      {...rest}
    >
      <div className="back-right-arrow">
        <RightArrow />
      </div>
      <PTag classes="text-dark-blue fn-17 fw-bold ms-2" texts={title} />
    </ATag>
  );
};

// SocialLogin
export const SocialLogin = ({ src, socialiconclass }) => {
  return (
    <ATag
      src={src}
      classes={`social-bg-icon mx-3`}
      children={<div className="social-icon">{socialiconclass}</div>}
    />
  );
};

// SocialLogin
export const SocialIcon = ({ src, socialiconclass }) => {
  return (
    <ATag
      src={src}
      classes={`mx-3 img-fluid`}
      children={<div className="social-icon">{socialiconclass}</div>}
    />
  );
};

// checkbox
export const Checkbox = ({
  id,
  For,
  Checkboxlabel,
  classes,
  name,
  value,
  ...rest
}) => {
  return (
    <LabelTag
      classes={`form-check checkbox d-flex align-items-center w-100 ${classes}`}
      text={
        <Fragment>
          <InputTag
            type={"checkbox"}
            classes={"form-check-input pointer"}
            id={id}
            name={name}
            checked={value}
            {...rest}
          />
          <LabelTag
            classes="form-check-label text-navy-blue pointer ms-1"
            For={For}
            text={Checkboxlabel}
          />
        </Fragment>
      }
    />
  );
};
