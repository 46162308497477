import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeLayout from "../../../layouts/defaultLayout/HomeLayout";
import IKeyIntro from "./IKeyIntro";

const WhyIKey = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index exact path="/" element={<IKeyIntro />} />
      </Route>
    </Routes>
  );
};

export default WhyIKey;
