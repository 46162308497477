import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "../../pages/Dashboard/NavBar";

export default function HomeLayout(props) {
  const location = useLocation();

  return (
    <div
      className={`w-full h-full ${
        location.pathname.includes("upload-image")
          ? ``
          : `xl:px-40 lg:px-28 sm:px-16 px-4`
      }`}
    >
      <div
        className={`${
          location.pathname.includes("upload-image")
            ? `xl:pl-40 lg:pl-28 sm:pl-16 pl-4`
            : ``
        }`}
      >
        <NavBar />
      </div>
      <Outlet />
    </div>
  );
}
