import create from "zustand";
import { persist } from "zustand/middleware";
import { axiosApi } from "../helpers/axiosTDB";

const collectionStore = create(
  persist(
    (set, get) => ({
      putCollectionData: {},
      getLeftBaseLineImageData: "",
      getRightBaseLineImageData: "",
      loading: false,
      error: null,

      putCollectionApi: async (payload) => {
        try {
          set({ loading: true, putCollectionData: {}, error: null });
          const response = await axiosApi.post(
            "/Collections/ImagePair",
            payload
          );

          set({
            loading: false,
            putCollectionData: response.data,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            putCollectionData: error.response.data.message,
            error: error.response.data.message,
          });
          return error.response.data.message;
        }
      },
      removeCollectionData: async () => {
        set({ loading: false, putCollectionData: {}, error: null });
      },
      getLeftBaseLineImageApi: async (payload) => {
        try {
          set({ loading: true, getLeftBaseLineImageData: "", error: null });
          const response = await axiosApi.get(
            "/Collections/" + payload,
            {
              responseType: "arraybuffer",
            },
            null
          );
          set({
            loading: false,
            getLeftBaseLineImageData: response?.request?.responseURL,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            getLeftBaseLineImageData: "",
            error: error.response.data.message,
          });
          return error.response.data.message;
        }
      },
      getRightBaseLineImageApi: async (payload) => {
        try {
          set({ loading: true, getRightBaseLineImageData: "", error: null });
          const response = await axiosApi.get(
            "/Collections/" + payload,
            {
              responseType: "arraybuffer",
            },
            null
          );
          set({
            loading: false,
            getRightBaseLineImageData: response?.request?.responseURL,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            getRightBaseLineImageData: "",
            error: error.response.data.message,
          });
          return error.response.data.message;
        }
      },
      ImageComparisonsAPI: async (payload) => {
        try {
          const response = await axiosApi.get(
            "/ImageComparisons/" + payload,
            null
          );
          return response;
        } catch (error) {
          return error.response.data.message;
        }
      },
    }),
    { name: "collection-storage", getStorage: () => sessionStorage }
  )
);

export default collectionStore;
