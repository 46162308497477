import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Logo } from "../../../assets/img";
import {
  DiscriptionTag,
  ImgTag,
  TitleTag,
} from "../../../components/design-components/MicroComponents";

const Question2 = (props) => {
  const { continueClick, data, index } = props;

  const [question, setQuestion] = useState();
  const [option, setOption] = useState();

  useEffect(() => {
    setQuestion(data?.bodyText);
    setOption(data?.answerOptions);
  }, [data]);

  const initialValues = {
    age: "",
  };

  const handleChange = (e) => {
    continueClick({
      question: index + 1,
      data: { id: data?.id, answer: e },
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        age: Yup.string().required("Selection is required"),
      })}
      onSubmit={(fields, { resetForm }) => {
        continueClick({
          question: index + 1,
          data: { id: data?.id, answer: fields.age },
        });
        resetForm();
      }}
      render={({ values, setFieldValue }) => (
        <div className="h-full flex md:justify-start justify-center">
          <Form className="h-full">
            <div className="h-full mt-auto md:mb-[-38px] ">
              <div className="mb-auto">
                <div className="flex justify-center md:justify-start">
                  <ImgTag src={Logo} classes="w-5 h-5 mr-2 my-auto" />
                  <DiscriptionTag
                    value={"Question " + (index + 1)}
                    classes="text-slate-600"
                  />
                </div>
                <TitleTag
                  value={question}
                  classes="mt-3 font-semibold text-center md:text-left"
                />
                <div className="mt-4">
                  <div
                    className={`${
                      option?.length > 3 ? `md:columns-2 columns-1` : ``
                    }`}
                  >
                    {option?.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center md:justify-start ${
                          index === 0 ? `mt-0` : `mt-3`
                        }`}
                      >
                        <input
                          hidden
                          type="radio"
                          id={item}
                          value={item}
                          name="age"
                          onChange={(e) => {
                            setFieldValue("age", e.target.value);
                            handleChange(e.target.value);
                          }}
                        />
                        <label
                          htmlFor={item}
                          className={`py-2 inline-block text-sm font-thin text-center border shadow-md rounded-xl w-56 ${
                            values.age === item
                              ? "bg-gradient-to-b from-[#1AC4F0] to-[#244E95] text-white"
                              : "text-black"
                          } `}
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <ErrorMessage
                  name="age"
                  component="div"
                  className="text-red-500 text-xs md:text-start text-center mt-1"
                />
              </div>

              {/* <div className="mt-10">
                <button
                  type="submit"
                  className="px-16 py-2 text-sm font-semibold text-center text-white border rounded-3xl mx-auto bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
                >
                  Continue
                </button>
              </div> */}
            </div>
          </Form>
        </div>
      )}
    />
  );
};

export default Question2;
