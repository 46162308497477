import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { HideIcon, ViewIcon } from "../../assets/img";
import { InputTag } from "../../components/design-components/MicroComponents";
import LoaderSmall from "../../components/loaderSmall";
import authStore from "../../stores/auth";

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { signUpApi, loading } = authStore((state) => ({
    signUpApi: state.signUpApi,
    loading: state.loading,
  }));

  const clickOnSignup = (fields) => {
    signUpApi(
      {
        client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        email: fields.email,
        password: fields.password,
        connection: "Username-Password-Authentication",
        given_name: fields.firstName,
        family_name: fields.lastName,
      },
      navigate
    );
    return false;
  };

  return (
    <>
      {loading && <LoaderSmall />}
      <Formik
        enableReinitialize
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          accept: false,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required("First name is required"),
          lastName: Yup.string().required("Last name is required"),
          email: Yup.string()
            .email("Email is invalid")
            .required("Email is required"),
          password: Yup.string().required("Password is required"),
          accept: Yup.bool().oneOf(
            [true],
            "You need to accept the terms of service & privacy policy"
          ),
        })}
        onSubmit={(fields) => {
          clickOnSignup(fields);
        }}
        render={({
          errors,
          status,
          touched,
          values,
          setFieldValue,
          handleChange,
        }) => (
          <Form className="w-full h-full">
            <div className="flex flex-col">
              <div className="mx-auto my-10">
                <div className="mt-2 flex flex-col">
                  <label className="font-thin text-xs text-gray-700">
                    First Name
                  </label>
                  <Field
                    as={InputTag}
                    name="firstName"
                    type="text"
                    placeholder="Enter Your First Name"
                    classes="text-sm border py-1.5 px-2 rounded w-68 shadow-sm"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-500 text-xs"
                  />
                </div>
                <div className="mt-5 flex flex-col">
                  <label className="font-thin text-xs text-gray-700">
                    Last Name
                  </label>
                  <Field
                    as={InputTag}
                    name="lastName"
                    type="text"
                    placeholder="Enter Your Last Name"
                    classes="text-sm border py-1.5 px-2 rounded w-68 shadow-sm"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-500 text-xs"
                  />
                </div>
                <div className="mt-5 flex flex-col">
                  <label className="font-thin text-xs text-gray-700">
                    Email
                  </label>
                  <Field
                    as={InputTag}
                    name="email"
                    type="email"
                    placeholder="Enter Your Email"
                    classes="text-sm border py-1.5 px-2 rounded w-68 shadow-sm"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-xs"
                  />
                </div>
                <div className="mt-5 flex flex-col">
                  <label className="font-thin text-xs text-gray-700">
                    Password
                  </label>
                  <Field
                    as={InputTag}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Provide a strong password"
                    classes="text-sm border py-1.5 px-2 rounded w-68 shadow-sm"
                  />
                  <div className="relative">
                    <div className="absolute right-2 top-[-17px] transform -translate-y-1/2">
                      {showPassword ? (
                        <img
                          src={ViewIcon}
                          alt="view_icon"
                          className="h-5 w-5 cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <img
                          src={HideIcon}
                          alt="hide_icon"
                          className="h-5 w-5 cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </div>
                  </div>

                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-xs"
                  />
                </div>
                <div className="mt-4 flex">
                  <Field name="accept" type="checkbox" />
                  <p className="text-xs grow ml-2 text-gray-500">
                    Agree to our{" "}
                    <b
                      className="cursor-pointer font-semibold"
                      onClick={() => navigate("/public/terms-condition")}
                    >
                      terms of service
                    </b>{" "}
                    &{" "}
                    <b
                      className="cursor-pointer font-semibold"
                      onClick={() => navigate("/public/privacy-policy")}
                    >
                      privacy policy
                    </b>
                  </p>
                </div>
                <ErrorMessage
                  name="accept"
                  component="div"
                  className="text-red-500 text-xs"
                />
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="px-4 py-2 mb-10 my-auto block text-sm font-semibold text-center text-white border p-2 rounded-3xl w-56 mx-auto bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      />
    </>
  );
};

export default SignUp;
