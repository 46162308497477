/* eslint-disable react/no-unescaped-entities */
import React from "react";

const LoaderEmail = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden backdrop-blur flex flex-col items-center justify-center z-[100]">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8 mb-4"></div>
    </div>
  );
};

export default LoaderEmail;
