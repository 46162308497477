import React from "react";
import { useNavigate } from "react-router-dom";
import { IKV } from "../../utils/constants";
import NavBar from "../Dashboard/NavBar";
import Cookies from "js-cookie";

const TermsCondition = () => {
  const navigate = useNavigate();

  const clickOnBack = () => {
    navigate(-1);
  };
  return (
    <div className="w-full h-full sm:px-16 px-8">
      {Cookies.get(IKV) && <NavBar />}
      <button
        onClick={clickOnBack}
        className="px-3 py-2 my-auto text-sm font-semibold text-center text-black border p-2 rounded-3xl w-20 mx-auto"
      >
        {"< Back"}
      </button>
      <div className="w-full h-full flex justify-start">
        <div className="flex flex-col">
          <p className="mb-3 font-medium mx-auto my-4 md:my-6 text-lg sm:text-xl md:text-2xl">
            Terms & Conditions
          </p>
          <p className="mx-auto font-thin text-gray-500 text-start no-scrollbar overflow-auto p-2">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-17c7d756 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="17c7d756"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container font-semibold">
                  iKey® Terms of Service &amp; End User License Agreement{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7be67c51 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="7be67c51"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  <p>
                    This End User License Agreement{" "}
                    <strong>(“Agreement”)</strong> is a binding agreement
                    between you, on your behalf and on behalf of the entity you
                    are representing if any{" "}
                    <strong>(“End User” or “you&nbsp;“)</strong>, and the
                    Company..
                  </p>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-3c99a732 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="3c99a732"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container font-semibold mt-2">
                  IMPORTANT: THIS AGREEMENT INCLUDES A CLASS ACTION WAIVER AND
                  RESOLUTION OF DISPUTES BY ARBITRATION INSTEAD OF IN COURT. SEE
                  SECTION 17 BELOW FOR MORE INFORMATION.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-1bbde173 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="1bbde173"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  BY CLICKING THE “AGREE” BUTTON, DOWNLOADING, INSTALLING OR
                  OTHERWISE USING THE APPLICATION OR CONTENT AND SERVICES
                  (DEFINED BELOW), YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
                  UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 16 YEARS
                  OF AGE OR OLDER AND OF LEGAL AGE TO ENTER INTO A BINDING
                  AGREEMENT (IF YOU ARE NOT OF LEGAL AGE TO ENTER INTO A BINDING
                  AGREEMENT, YOU HAVE OBTAINED YOUR PARENT’S OR GUARDIAN’S
                  ACCEPTANCE TO THIS AGREEMENT ON YOUR BEHALF); AND (C) ACCEPT
                  THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS
                  TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD,
                  INSTALL OR OTHERWISE USE THE APPLICATION OR CONTENT AND
                  SERVICES AND DELETE THE APPLICATION FROM YOUR DEVICE.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-365f7798 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="365f7798"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  IN SOME INSTANCES, ADDITIONAL OR DIFFERENT TERMS POSTED ON THE
                  APPLICATION APPLY TO YOUR USE OF CERTAIN PARTS OF THE
                  APPLICATION (INDIVIDUALLY AND COLLECTIVELY “ADDITIONAL
                  TERMS”). ANY ADDITIONAL TERMS SHALL BE MADE A PART OF AND
                  INCORPORATED BY REFERENCE INTO THIS AGREEMENT. ANY VIOLATION
                  OF ADDITIONAL TERMS SHALL BE A VIOLATION OF THIS AGREEMENT. TO
                  THE EXTENT THERE IS A CONFLICT BETWEEN THIS AGREEMENT AND ANY
                  ADDITIONAL TERMS, THE ADDITIONAL TERMS WILL CONTROL UNLESS THE
                  ADDITIONAL TERMS EXPRESSLY STATE OTHERWISE.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-1b7db129 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="1b7db129"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  YOU UNDERSTAND THAT THE PROPER OPERATION OF THE APPLICATION
                  DEPENDS ON YOUR ENTRY OF TRUTHFUL AND ACCURATE INFORMATION
                  WHERE APPLICABLE. YOU AGREE, REPRESENT AND WARRANT THAT ALL
                  INFORMATION YOU ENTER INTO THE APPLICATION IS TRUTHFUL AND
                  ACCURATE. MOREOVER, YOU UNDERSTAND THAT YOUR VERSION OF THE
                  APPLICATION, OR A PART THEREOF MAY BE CONFIGURED BY A THIRD
                  PARTY, SUCH AS AN OPERATOR OF A BUSINESS OR OTHER ORGANIZATION
                  (“THIRD PARTY CONFIGURER”), WHICH YOU MAY INTEND TO VISIT,
                  WHEREIN SUCH CONFIGURATION MAY PROVIDE OR DENY YOU ACCESS TO A
                  LOCATION, BUSINESS OR ORGANIZATION. YOU UNDERSTAND THAT
                  COMPANY IS NOT RESPONSIBLE IN ANY WAY FOR THE DECISION OF THE
                  THIRD PARTY CONFIGURER TO PERMIT OR DENY YOU SUCH ACCESS AND
                  COMPANY IS NOT RESPONSIBLE IN ANY WAY FOR SUCH CONFIGURATION,
                  INCLUDING THE OPERATION OF THE APPLICATION BASED ON SUCH
                  CONFIGURATION. TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU
                  ASSUME ALL RISKS RELATED TO THE USE OF THE OPERATION OF THE
                  APPLICATION BASED ON THE CONFIGURATION OF SUCH THIRD PARTY.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-4dab6fb7 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="4dab6fb7"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  IF YOU ARE A PERSONNEL OF SUCH THIRD PARTY CONFIGURER, YOU
                  AGREE ON BEHALF OF SUCH THIRD PARTY CONFIGURER THAT COMPANY IS
                  NOT RESPONSIBLE IN ANY WAY FOR THE TRUTHFULNESS OR ACCURACY OF
                  THE INFORMATION ENTERED BY YOU OR OTHER END USERS, AND THAT
                  COMPANY IS NOT RESPONSIBLE IN ANY WAY FOR THE CONFIGURATION
                  YOU OR SUCH THIRD PARTY CONFIGURER USE TO CONFIGURE A VERSION
                  OF THE APPLICATION OR A PART THEREOF. TO THE MAXIMUM EXTENT
                  PERMITTED BY LAW, YOU AGREE ON BEHALF OF SUCH THIRD PARTY
                  CONFIGURER THAT THE THIRD PARTY CONFIGURER SHALL BE SOLELY
                  RESPONSIBLE FOR THE CONFIGURATION AND ALL DECISIONS TO PROVIDE
                  OR DENY ACCESS TO ANY END USERS.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-45eaa407 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="45eaa407"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  <ol>
                    <li>
                      <strong>
                        <u>License Grant.</u>
                      </strong>
                      Subject to the terms of this Agreement, Company grants you
                      a limited, non-exclusive, and nontransferable license to
                      (a) download, install, and use the Application and (b)
                      access and use the Content and Services via the
                      functionality of the Application, in each case for your
                      personal, non-commercial use on a single device owned or
                      otherwise controlled by you (“<strong>Device</strong>“)
                      strictly in accordance with the Application’s
                      documentation.
                    </li>
                  </ol>
                  <ol>
                    <li>
                      <strong>
                        <u>License Restrictions.</u>
                      </strong>
                      &nbsp;You shall not access or use the iKey Property
                      (defined below) other than as expressly permitted herein.
                      Without limiting the generality of the foregoing, you
                      shall not:
                    </li>
                  </ol>
                  <ol>
                    <li>
                      copy the iKey Property, except as expressly permitted by
                      this license;
                    </li>
                    <li>
                      modify, translate, adapt, or otherwise create derivative
                      works or improvements, whether or not patentable, of the
                      iKey Property;
                    </li>
                    <li>
                      reverse engineer, disassemble, decompile, decode, or
                      otherwise attempt to derive or gain access to the source
                      code of the iKey Property or any part thereof;
                    </li>
                    <li>
                      remove, delete, alter, or obscure any trademarks or any
                      copyright, trademark, patent, or other intellectual
                      property or proprietary rights notices from the iKey
                      Property, including any copy thereof;
                    </li>
                    <li>
                      rent, lease, lend, sell, sublicense, assign, distribute,
                      publish, transfer, or otherwise make available the iKey
                      Property, or any features or functionality of the iKey
                      Property, to any third party for any reason, including by
                      making the iKey Property available on a network where it
                      is capable of being accessed by more than one device at
                      any time; or
                    </li>
                    <li>
                      remove, disable, circumvent, or otherwise create or
                      implement any workaround to any copy protection, rights
                      management, or security features in or protecting the iKey
                      Property; or
                    </li>
                    <li>
                      use the iKey Property in, or in association with, the
                      design, construction, maintenance, or operation of any
                      hazardous environments or systems, including any power
                      generation systems; aircraft navigation or communication
                      systems, air traffic control systems, or any other
                      transport management systems; safety-critical
                      applications, including medical or life-support systems,
                      vehicle operation applications or any police, fire, or
                      other safety response systems; and military or aerospace
                      applications, weapons systems, or environments.
                    </li>
                    <li>
                      use the Application, or any associated services, including
                      customer support services, in a manner that: (i) presents
                      a risk to the safety, wellbeing or health of Company
                      employees or agents; (ii) restricts or inhibits any other
                      person from using or enjoying any aspect of the
                      Application; (iii) may expose Company or users of the
                      Application to any harm or liability of any type; or (iv)
                      involves the publication of false, misleading, defamatory,
                      offensive or otherwise objectionable content or subjects
                      Company and/ or staff to same. In the event of same, then
                      Company reserves the right to terminate your iKey account
                      immediately and prevent your use of the Service or
                      Application.
                      <ol>
                        <li>
                          <strong>
                            <u>Reservation of Rights.</u>
                          </strong>
                          You acknowledge and agree that the Application and
                          Content and Services and any other Company products
                          and services (collectively, “iKey Property”) is
                          provided under license, and not sold, to you. You do
                          not acquire any ownership interest in the iKey
                          Property under this Agreement, or any other rights
                          thereto other than to use the iKey Property in
                          accordance with the license granted, and subject to
                          all terms, conditions, and restrictions, under this
                          Agreement. Company and its licensors and service
                          providers reserve and shall retain their entire right,
                          title, and interest in and to the iKey Property
                          including all copyrights, trademarks, and other
                          intellectual property rights therein or relating
                          thereto, except as expressly granted to you in this
                          Agreement.
                        </li>
                        <li>
                          <strong>
                            <u>Collection and Use of Your Information.</u>
                          </strong>
                          You acknowledge that when you download, install, or
                          use the Application, Company may use automatic means
                          (including, for example, cookies and web beacons) to
                          collect information about your Device and about your
                          use of the Application. You also may be required to
                          provide certain information about yourself as a
                          condition to downloading, installing, or using the
                          Application or certain of its features or
                          functionality, and the Application may provide you
                          with opportunities to share information about yourself
                          with others. All information we collect through or in
                          connection with this Application is subject to
                          our&nbsp;Privacy Policy. By downloading, installing,
                          using, and providing information to or through this
                          Application, you consent to all actions taken by us
                          with respect to your information in compliance with
                          the Privacy Policy.
                        </li>
                        <li>
                          <strong>
                            <u>Content and Services.</u>
                          </strong>
                          The Application may provide you with access to
                          Company’s website located at&nbsp;
                          <a href="http://www.ikey.ie">www.ikey.ie</a>&nbsp;(the
                          “<strong>Website</strong>“) and products and services
                          accessible thereon, and certain features,
                          functionality, and content accessible on or through
                          the Application may be hosted on the Website
                          (collectively, “<strong>Content and Services</strong>
                          “). Your access to and use of the Application and such
                          Content and Services are governed by this Agreement
                          and any applicable Additional Terms, including the
                          Website’s Terms of Use and the Privacy Policy. This
                          Agreement, the Additional Terms, and the Terms of Use
                          are subject to change by Company at any time in its
                          discretion. Your use of any iKey Property (including
                          the Application or Content and Services) after such
                          changes are implemented constitutes your
                          acknowledgement and acceptance of the changes. Please
                          consult this Agreement and the Terms of Use regularly.
                          Your access to and use of certain Content and Services
                          may require you to acknowledge your acceptance of the
                          Agreement and Additional Terms and/or to register with
                          the Application and/or Website, and your failure to do
                          so may restrict you from accessing or using certain of
                          the Application’s features and functionality.
                        </li>
                      </ol>
                    </li>
                  </ol>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-29b373c4 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="29b373c4"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  Availability of and prices for our Application, Content and
                  Services, and other products and services are subject to
                  change without notice. Company reserves the right at any time
                  to modify or discontinue any of our Application, Content and
                  Services, and other products and services (or any part or
                  content thereof) without notice at any time. Company shall not
                  be liable to you or to any third-party for any modification,
                  price change, suspension or discontinuance of our products and
                  services.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-5fdde141 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="5fdde141"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  In the event any product or service is listed at an incorrect
                  price due to typographical error or error in pricing
                  information for any other reason, Company shall have the right
                  to refuse or cancel any orders placed listed at the incorrect
                  price. Company shall have the right to refuse or cancel any
                  such orders whether or not the order has been confirmed and
                  your credit or debit card has been charged.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7d36e947 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="7d36e947"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  <p>
                    <strong>
                      <u>Updates.</u>
                    </strong>
                    Company may from time to time in its sole discretion develop
                    and provide Application and Content and Services updates,
                    which may include upgrades, bug fixes, patches, other error
                    corrections, and/or new features (collectively, including
                    related documentation, ”&nbsp;<strong>Updates</strong>“).
                    Updates may also modify or delete in their entirety certain
                    features and functionality. You agree that Company has no
                    obligation to provide any Updates or to continue to provide
                    or enable any particular features or functionality, and that
                    Company reserves the right to charge fees for iKey Property
                    (including Application and/or Content and Services) in the
                    future, as set forth in Additional Terms. Based on your
                    Device settings, when your Device is connected to the
                    internet either, in Company’s sole discretion:
                  </p>
                  <ol>
                    <li>
                      the Application may automatically download and install all
                      available Updates; or
                    </li>
                    <li>
                      you may receive notice of or be prompted to download and
                      install available Updates.
                    </li>
                  </ol>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-71ae075e de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="71ae075e"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  You shall promptly download and install all Updates and
                  acknowledge and agree that the Application or portions thereof
                  may not properly operate should you fail to do so. You further
                  agree that all Updates will be deemed part of the Application
                  and be subject to all terms and conditions of this Agreement.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-3f7d8a03 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="3f7d8a03"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  <p>
                    9. Third-Party Materials.The Application may display,
                    include, or make available third-party content (including
                    data, information, applications, and other products,
                    services, and/or materials) or provide links to third-party
                    websites or services, including through third-party
                    advertising (“Third-Party Materials”). You acknowledge and
                    agree that Company is not responsible for Third-Party
                    Materials, including their accuracy, completeness,
                    timeliness, validity, copyright compliance, legality,
                    decency, quality, or any other aspect thereof. Company does
                    not assume and will not have any liability or responsibility
                    to you or any other person or entity for any Third-Party
                    Materials. Third-Party Materials and links thereto are
                    provided solely as a convenience to you, and you access and
                    use them entirely at your own risk and subject to such third
                    parties’ terms and conditions.
                  </p>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-381a80c3 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="381a80c3"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  <p>
                    9. Third-Party Materials.The Application may display,
                    include, or make available third-party content (including
                    data, information, applications, and other products,
                    services, and/or materials) or provide links to third-party
                    websites or services, including through third-party
                    advertising (“Third-Party Materials”). You acknowledge and
                    agree that Company is not responsible for Third-Party
                    Materials, including their accuracy, completeness,
                    timeliness, validity, copyright compliance, legality,
                    decency, quality, or any other aspect thereof. Company does
                    not assume and will not have any liability or responsibility
                    to you or any other person or entity for any Third-Party
                    Materials. Third-Party Materials and links thereto are
                    provided solely as a convenience to you, and you access and
                    use them entirely at your own risk and subject to such third
                    parties’ terms and conditions.
                  </p>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-3164c1db de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="3164c1db"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  <p>
                    <strong>
                      <u>10. Term and Termination.</u>
                    </strong>
                  </p>
                  <ol>
                    <li>
                      The term of Agreement commences when you download or
                      install the Application or otherwise acknowledge your
                      acceptance (whichever occurs first) and will continue in
                      effect until terminated by you or Company .
                    </li>
                    <li>
                      You may terminate this Agreement by deleting the
                      Application and all copies thereof from your Device.
                    </li>
                    <li>
                      Company may terminate this Agreement at any time without
                      notice. In addition, this Agreement will terminate
                      immediately and automatically without any notice if you
                      violate any of the terms and conditions of this Agreement.
                    </li>
                    <li>
                      Upon termination:
                      <ol>
                        <li>
                          all rights granted to you under this Agreement will
                          also terminate; and
                        </li>
                        <li>
                          you must cease all use of the Application and delete
                          all copies of the Application from your Device and
                          account.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Termination will not limit any of Company’s rights or
                      remedies at law or in equity. All provisions of the
                      Agreement (including any applicable Additional Terms) that
                      by their nature should survive termination or expiration
                      of this Agreement shall so survive (including, without
                      limitation, any provisions relating to Company’s
                      intellectual property rights, disclaimer of warranties,
                      limitation of liability, indemnity, governing law,
                      limitation of time to file claims, arbitration, and class
                      action waivers.
                    </li>
                  </ol>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-4a6a75e5 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="4a6a75e5"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  11. Disclaimer of Warranties.THE iKey PROPERTY IS PROVIDED TO
                  END USER “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT
                  WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER
                  APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF
                  ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND
                  SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER
                  EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
                  iKey PROPERTY, INCLUDING ALL IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                  NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE
                  OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE.
                  WITHOUT LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO
                  WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY
                  KIND THAT THE iKey PROPERTY WILL MEET YOUR REQUIREMENTS,
                  ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY
                  OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE
                  WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY
                  STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN
                  OR WILL BE CORRECTED.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-1c1fc6f1 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="1c1fc6f1"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR
                  LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE
                  APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF
                  THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-1b86729e de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="1b86729e"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  12. <u>Limitation of Liability.</u>TO THE FULLEST EXTENT
                  PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS
                  AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR
                  SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED
                  TO THIS AGREEMENT (WHICH, FOR THE AVOIDANCE OF DOUBT, SHALL
                  INCLUDE ANY APPLICABLE ADDITIONAL TERMS), INCLUDING WITHOUT
                  LIMITATION YOUR USE OF OR INABILITY TO ACCESS AND/OR USE THE
                  APPLICATION OR THE CONTENT AND SERVICES FOR:
                  <ol>
                    <li>
                      PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF
                      SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF
                      GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR
                      MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL,
                      INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.
                    </li>
                    <li>
                      DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE
                      AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION OR CONTENT
                      AND SERVICES OR $100, WHICHEVER IS GREATER.
                    </li>
                  </ol>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-13d7710c de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="13d7710c"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES
                  ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
                  OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE
                  FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS
                  OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF
                  LIABILITY MAY NOT APPLY TO YOU.{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-905a84a de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="905a84a"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  <p>
                    <strong>
                      <u>13. Indemnification.</u>
                    </strong>
                    You agree to indemnify, defend, and hold harmless Company
                    and its officers, directors, employees, agents, affiliates,
                    successors, and assigns from and against any and all losses,
                    damages, liabilities, deficiencies, claims, actions,
                    judgments, settlements, interest, awards, penalties, fines,
                    costs, or expenses of whatever kind, including reasonable
                    attorneys’ fees, arising from or relating to your use or
                    misuse of the Application, Content and Services, or other
                    iKey Property or your breach of this Agreement (which, for
                    the avoidance of doubt, shall include any applicable
                    Additional Terms), including but not limited to your
                    Submissions.
                  </p>
                  <p>
                    <strong>
                      <u>14. Export and Import Regulation.</u>
                    </strong>
                    The Application may be subject to US export control laws,
                    including the Export Control Reform Act and its associated
                    regulations or import regulations in the country in which
                    you reside. You shall not, directly or indirectly, export,
                    re-export, or release the Application to, or make the
                    Application accessible from, any jurisdiction or country to
                    which export, re-export, import, or release is prohibited by
                    law, rule, or regulation. You shall comply with all
                    applicable national and federal laws, regulations, and
                    rules, and complete all required undertakings (including
                    obtaining any necessary export or import license or other
                    governmental approval), prior to exporting, re-exporting,
                    importing, releasing, or otherwise using or making the
                    Application available outside the US or in the country in
                    which you reside.
                  </p>
                  <p>
                    <strong>
                      <u>15. US Government Rights.</u>
                    </strong>
                    The Application is commercial computer software, as such
                    term is defined in 48 C.F.R. §2.101. Accordingly, if you are
                    an agency of the US Government or any contractor therefor,
                    you receive only those rights with respect to the
                    Application as are granted to all other end users under
                    license, in accordance with (a) 48 C.F.R. §
                    <a href="tel:227.7201">227.7201</a>&nbsp;through 48 C.F.R. §
                    <a href="tel:227.7204">227.7204</a>, with respect to the
                    Department of Defense and their contractors, or (b) 48
                    C.F.R. §12.212, with respect to all other US Government
                    licensees and their contractors.
                  </p>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-6682ea69 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="6682ea69"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container mt-2">
                  <p>
                    <strong>
                      <u>16. Severability.</u>
                    </strong>
                    If any provision of this Agreement is illegal or
                    unenforceable under applicable law, the remainder of the
                    provision will be amended to achieve as closely as possible
                    the effect of the original term and all other provisions of
                    this Agreement will continue in full force and effect.
                  </p>
                  <p>
                    <strong>
                      <u>17. Entire Agreement. </u>
                    </strong>
                    This Agreement, the other documents referenced herein
                    (including any Additional Terms) and our Privacy Policy
                    constitute the entire agreement between you and Company with
                    respect to the Application and supersede all prior or
                    contemporaneous understandings and agreements, whether
                    written or oral, with respect to the Application, Content
                    and Services, and other iKey Property.
                  </p>
                  <p>
                    <strong>
                      <u>18. Waiver.</u>
                    </strong>
                    No failure to exercise, and no delay in exercising, on the
                    part of either party, any right or any power hereunder shall
                    operate as a waiver thereof, nor shall any single or partial
                    exercise of any right or power hereunder preclude further
                    exercise of that or any other right hereunder.
                  </p>{" "}
                </div>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
