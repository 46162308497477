import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeLayout from "../../../layouts/defaultLayout/HomeLayout";
import ProfileMenu from "./ProfileMenu";

const Profile = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index path="/" element={<ProfileMenu />} />
      </Route>
    </Routes>
  );
};

export default Profile;
