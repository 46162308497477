import { Suspense } from "react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import LazyloadLoader from "../components/common/LazyLoader";
import AuthLayout from "../layouts/authLayout/AuthLayout";
import DefaultLayout from "../layouts/defaultLayout/DefaultLayout";
import AuthRoute from "../pages/Authentication";
import DashboardRoute from "../pages/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "../pages/PublicPage";
import PublicLayout from "../layouts/defaultLayout/PublicLayout";

const AppRoutes = ({ email_verified }) => {
  return (
    <Suspense fallback={<LazyloadLoader />}>
      <Routes>
        {/* auth layout */}
        <Route path="/" element={<AuthLayout />}>
          <Route
            path="/*"
            element={<AuthRoute email_verified={email_verified} />}
          />
        </Route>

        <Route path="/" element={<RRR />}>
          <Route path="dashboard/*" element={<DashboardRoute />} />
        </Route>

        <Route path="/" element={<PublicLayout />}>
          <Route path="public/*" element={<PublicRoute />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;

function RRR() {
  return (
    <>
      <ProtectedRoute />
      <DefaultLayout />
    </>
  );
}
