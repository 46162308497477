import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormLogo, SmilyIcon } from "../../assets/img";
import {
  ImgTag,
  TitleTag,
} from "../../components/design-components/MicroComponents";
import LoaderEmail from "../../components/loaderEmail";
import { axiosApi } from "../../helpers/axiosTDB";
import personStore from "../../stores/person";
import { IKV } from "../../utils/constants";
import Cookies from "js-cookie";

const ConfirmEmail = ({ email_verified }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = `${process.env.REACT_APP_DOMAIN}`;
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
        const encodedString = btoa(accessToken);
        Cookies.set(IKV, encodedString, {
          secure: true,
        });
        axiosApi.defaults.headers.common["Authorization"] = `Bearer ${atob(
          encodedString
        )}`;
        let formdata = new FormData();
        formdata.append("email", user?.email);
        formdata.append("family_name", user?.family_name);
        formdata.append("given_name", user?.given_name);
        formdata.append("user_id", user?.sub);
        formdata.append("auth0Code", user?.sub?.replaceAll("auth0|", ""));
        addPersonApi(formdata);
      } catch (e) {}
      navigate("/dashboard");
    };
    if (email_verified === true) {
      getUserMetadata();
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [user]);

  const { addPersonApi } = personStore((state) => ({
    addPersonApi: state.addPersonApi,
  }));

  const clickOnDashboard = () => {
    loginWithRedirect();
  };

  return loader ? (
    <>
      <LoaderEmail />
    </>
  ) : (
    loader === false && !email_verified && (
      <div className="md:basis-2/3 lg:basis-1/2 xl:basis-1.5/3 2xl:basis-1/3 m-auto px-5 sm:px-20 md:px-24">
        <div className="flex flex-col bg-slate-50 rounded-lg sm:px-12 py-4">
          <div className="w-full h-full flex flex-col">
            <ImgTag src={FormLogo} classes="h-28 w-28 my-12 mx-auto" />
            <TitleTag
              value="Confirm Your Email"
              classes="mx-auto font-medium"
            />
            <p className="mx-auto mt-6 text-slate-400 font-light break-normal w-64 text-center">
              We have sent you an email to
              <b className="font-bold"> verify your email address, </b>please
              check your inbox or spam and click on the link.
            </p>
            <div className="my-16 mx-auto">
              <TitleTag value="Thank You" classes="mx-auto font-medium" />
              <ImgTag src={SmilyIcon} classes="h-10 w-10 mt-6 mx-auto" />
            </div>

            <div className="form-group">
              <button
                onClick={clickOnDashboard}
                className="px-4 py-2 mb-10 my-auto block text-sm font-semibold text-center text-white border p-2 rounded-3xl w-56 mx-auto bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
                placeholder="6 Digit Access Code Here"
              >
                Back to Log-in
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ConfirmEmail;
