import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/tailwind.css";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    redirectUri={`${process.env.REACT_APP_HOST}/confirm-email`}
    domain={`${process.env.REACT_APP_DOMAIN}`}
    clientId={`${process.env.REACT_APP_CLIENT_ID}`}
    audience={`${process.env.REACT_APP_AUDIENCE}`}
    scope="read:current_user update:current_user_metadata"
    useRefreshTokens="true"
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
);

reportWebVitals();
