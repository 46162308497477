import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsCondition from "./TermsCondition";

const PublicRoute = () => {
  return (
    <Routes>
      <Route exact path="/terms-condition" element={<TermsCondition />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default PublicRoute;
