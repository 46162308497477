import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { WelComeMobileImage } from "../../../assets/img";
import {
  DiscriptionTag,
  ImgTag,
} from "../../../components/design-components/MicroComponents";

const ThankYou = () => {
  const navigate = useNavigate();
  const clickOnStart = () => {
    clearInterval(interval);
    navigate("/dashboard/upload-image");
  };

  const [changeLayout, setChangeLayout] = useState("thank-you");

  const interval = setInterval(function () {
    clearInterval(interval);
    navigate("/dashboard/upload-image");
  }, 1000);

  return (
    <div className="w-full h-full flex">
      <p className="lg:basis-1/2 hidden lg:block"></p>
      <div className="lg:basis-1/2 m-auto w-full">
        {changeLayout === "thank-you" ? (
          <div className="lg:px-12">
            <ImgTag
              src={WelComeMobileImage}
              classes="mx-auto h-40 block sm:hidden mb-4"
            />
            <p className="text-black sm:text-white font-bold text-4xl md:text-6xl lg:text-7xl text-center md:text-start">
              Thank you
            </p>
          </div>
        ) : (
          <div className="lg:px-12">
            <p className="text-black sm:text-white font-bold text-4xl">
              Upload your images
            </p>
            <ImgTag
              src={WelComeMobileImage}
              classes="mx-auto h-40 block sm:hidden my-4"
            />
            <DiscriptionTag
              value="iKey® is a patented disruptive A.I. software which detects silent sight threatening 
changes. iKey® is not a diagnostic tool, it’s a change detection system designed to 
alert changes on your optic nerve. It allows you to record images of your eyes over 
your lifetime which are stored for future sight-wellness; including screening, 
telemedicine or specialist eye review."
              classes="text-black sm:text-white text-xs sm:text-sm md:text-lg mt-2"
            />

            <button
              className="my-10 text-sm font-semibold text-center text-white p-3 rounded-3xl w-72 bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
              onClick={clickOnStart}
            >
              Upload now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThankYou;
