import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FooterLogoBg, FormLogo } from "../../../assets/img";
import {
  ImgTag,
  InputTag,
  PTag,
  TitleTag,
} from "../../../components/design-components/MicroComponents";
import LoaderSmall from "../../../components/loaderSmall";
import personStore from "../../../stores/person";

const ContactMenu = () => {
  const { addContactApi, loading } = personStore((state) => ({
    addContactApi: state.addContactApi,
    loading: state.loading,
  }));
  return (
    <div className="w-full h-full">
      {loading && <LoaderSmall />}
      <div className="w-full h-full">
        <div className="sm:flex h-full">
          <div className="md:basis-1/3 lg:basis-1/2 xl:basis-2/3 2xl:basis-2/3 flex items-end mb-[70px] sm:mb-[140px]">
            <div className="hidden sm:block">
              <ImgTag src={FooterLogoBg} classes="lg:h-12 h-8" />
              <PTag
                value="We Want To Hear From You."
                classes="text-white lg:text-2xl text-md font-thin break-normal lg:w-96 w-52"
              />
            </div>
          </div>
          <div className="md:basis-2/3 lg:basis-1/2 xl:basis-1.5/3 2xl:basis-1/3 m-auto px-5 sm:px-20 md:px-24">
            <div className="flex flex-col bg-slate-50 rounded-lg sm:px-12">
              <Formik
                enableReinitialize
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                  name: Yup.string().required("Name is required"),
                  message: Yup.string().required("Message is required"),
                })}
                onSubmit={(fields, { resetForm }) => {
                  let formdata = new FormData();
                  formdata.append("email", fields?.email);
                  formdata.append("person", fields?.name);
                  formdata.append("comment", fields?.message);
                  addContactApi(formdata);
                  resetForm();
                }}
                render={({ errors, status, touched, values }) => (
                  <Form className="w-full h-full flex flex-col">
                    <ImgTag src={FormLogo} classes="h-28 w-28 my-2 mx-auto" />
                    <TitleTag
                      value="Contact us"
                      classes="mx-auto font-medium"
                    />
                    <div className="mx-auto">
                      <div className="mt-4 flex flex-col">
                        <label className="font-thin text-xs text-gray-700">
                          Name
                        </label>
                        <Field
                          as={InputTag}
                          name="name"
                          type="text"
                          value={values?.name}
                          placeholder="Enter Your Name"
                          classes={
                            "text-sm border py-1.5 px-2 rounded w-64 shadow-sm"
                          }
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="mt-4 flex flex-col">
                        <label className="font-thin text-xs text-gray-700">
                          Email
                        </label>
                        <Field
                          as={InputTag}
                          name="email"
                          type="email"
                          value={values?.email}
                          placeholder="Enter Your Email"
                          classes={
                            "text-sm border py-1.5 px-2 rounded w-64 shadow-sm"
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="mt-4 flex flex-col">
                        <label
                          className="font-thin text-xs text-gray-700"
                          style={{}}
                        >
                          We want to hear from you
                        </label>
                        <Field
                          component="textarea"
                          rows="4"
                          as={InputTag}
                          name="message"
                          type="textarea"
                          value={values?.message}
                          placeholder="Enter Your Message Here"
                          classes={
                            "text-sm border py-1.5 px-2 rounded w-64 shadow-sm"
                          }
                          style={{
                            padding: "4px",
                            borderWidth: "1px",
                            borderRadius: "0.25rem",
                            maxHeight: 80,
                            minHeight: 50,
                            fontSize: "13px",
                          }}
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>

                    <div className="form-group mt-4">
                      <button
                        type="submit"
                        className="px-4 py-2 mb-10 my-auto block text-sm font-semibold text-center text-white border p-2 rounded-3xl w-56 mx-auto bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
                        placeholder="6 Digit Access Code Here"
                      >
                        Send
                      </button>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMenu;
