import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { UploadFileBg } from "../../../assets/img";
import { ImgTag } from "../../../components/design-components/MicroComponents";

const MyDropzone = ({
  success,
  imageArray,
  setRightEye,
  rightEye,
  clickOnEye,
  id,
  label,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setRightEye(acceptedFiles[0]);
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div {...getRootProps()} onClick={() => clickOnEye()}>
      <input
        {...getInputProps()}
        disabled={
          !success && imageArray?.length !== 0 && imageArray[0]?.isBaseline
        }
        hidden
        type="file"
        id={id}
        accept="image/png, image/jpg, image/jpeg"
        onChange={(e) => setRightEye(e.target.files[0])}
      />
      {/* <input {...getInputProps()} /> */}
      {/* {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )} */}
      {rightEye ? (
        <label
          htmlFor={id}
          onClick={() => clickOnEye()}
          className="rounded-md sm:rounded-xl bg-white w-24 h-24 sm:w-36 sm:h-36 md:w-40 md:h-40 mr-8 flex justify-center items-center"
        >
          <ImgTag src={window.URL.createObjectURL(rightEye)} />
        </label>
      ) : (
        <label
          onClick={() => clickOnEye()}
          htmlFor={id}
          className="inline-block rounded-md sm:rounded-xl bg-white w-24 h-24 sm:w-36 sm:h-36 md:w-40 md:h-40 mr-8"
        >
          <div className="flex flex-col justify-center items-center h-full">
            <p className="font-medium text-black text-[10px] sm:text-lg md:text-lg">
              {label}
            </p>
            <ImgTag
              src={UploadFileBg}
              classes="sm:w-10 md:w-16 w-6 h-6 sm:h-10 md:h-14 my-1 md:my-3 mx-auto"
            />
            <p className="font-thin text-gray-700 text-[10px] sm:text-sm md:text-md">
              Upload file or
            </p>
            <p className="font-thin text-gray-500 text-[6px] md:text-xs">
              Drop your file
            </p>
          </div>
        </label>
      )}
    </div>
  );
};
export default MyDropzone;
