import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Logo } from "../../../assets/img";
import {
  DiscriptionTag,
  ImgTag,
  TitleTag,
} from "../../../components/design-components/MicroComponents";
import LoaderSmall from "../../../components/loaderSmall";
import personStore from "../../../stores/person";

const ProfileMenu = () => {
  const [dateArray, setDate] = useState();
  const [monthArray, setMonth] = useState();
  const [yearArray, setYear] = useState();
  const [loader, setLoader] = useState(false);

  const [date, setD] = useState();
  const [month, setM] = useState();
  const [year, setY] = useState();
  const navigate = useNavigate();

  const handleChange = (e, index) => {
    setLoader(true);
    let newArr = [...dummyArray];
    newArr[index].answer = e.target.value;
    setDummyArray(newArr);

    putAnswersApi({
      id: dummyArray?.[index]?.questionId,
      answer: e.target.value,
    }).then((res) => {
      setLoader(false);
      if (res.status === 200) {
        toast.success("Profile update successfully!");
      }
      if (res?.response?.status === 409) {
        toast.error(res?.response?.data.message);
      }
    });
  };

  useEffect(() => {
    if (
      (year &&
        year !== Number(moment(dummyArray?.[0]?.answer).format("yyyy"))) ||
      (month &&
        month !== Number(moment(dummyArray?.[0]?.answer).format("MM"))) ||
      (date && date !== Number(moment(dummyArray?.[0]?.answer).format("DD")))
    ) {
      setLoader(true);
      putAnswersApi({
        id: "0000-date-of-birth",
        answer: `${year}-${Number(month).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}-${Number(date).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}T00:00:00.000`,
      }).then((res) => {
        setLoader(false);
        if (res.status === 200) {
          toast.success("Profile update successfully!");
        }
        if (res?.response?.status === 409) {
          toast.error(res?.response?.data.message);
        }
      });
    }
  }, [year, month, date]);

  useEffect(() => {
    let dummyArray = [];
    for (let index = 1; index <= 31; index++) {
      dummyArray.push({ value: index });
    }
    setDate(dummyArray);
    let dummyArray1 = [];
    for (let index = 1; index <= 12; index++) {
      dummyArray1.push({ value: index });
    }
    setMonth(dummyArray1);
    let dummyArray2 = [];
    var currentYear = new Date().getFullYear();
    let startYear = 2019 - 20;
    startYear = 1980;
    while (startYear <= currentYear) {
      dummyArray2.push({ value: startYear++ });
    }
    setYear(dummyArray2);
  }, []);

  const { findPersonApi, getPerson, putAnswersApi, loading } = personStore(
    (state) => ({
      loading: state.loading,
      findPersonApi: state.findPersonApi,
      getPerson: state.getPerson,
      putAnswersApi: state.putAnswersApi,
    })
  );

  const [dummyArray, setDummyArray] = useState(getPerson?.questionnaire);

  useEffect(() => {
    if (dummyArray?.[0]?.questionId === "0000-date-of-birth") {
      setM(Number(moment(dummyArray?.[0]?.answer).format("MM")));
      setD(Number(moment(dummyArray?.[0]?.answer).format("DD")));
      setY(Number(moment(dummyArray?.[0]?.answer).format("yyyy")));
    }
  }, [dummyArray]);

  useEffect(() => {
    findPersonApi();
  }, [findPersonApi]);

  return (
    <>
      {loader && <LoaderSmall />}
      {loading && <LoaderSmall />}
      {!loading &&
        (dummyArray?.[dummyArray?.length - 1]?.answer ? (
          <div className="w-full h-full py-4 relative">
            <div className="no-scrollbar overflow-auto w-full h-full pb-12">
              <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-4">
                {dummyArray?.map((item, index) =>
                  item?.questionId.includes("date-of-birth") ? (
                    <div className="md:mt-4 mt-8 w-full" key={index}>
                      <div className="mb-auto">
                        <div className="flex justify-center md:justify-start">
                          <ImgTag src={Logo} classes="w-5 h-5 mr-2 my-auto" />
                          <DiscriptionTag
                            value={"Question " + (index + 1)}
                            classes="text-slate-600"
                          />
                        </div>
                        <TitleTag
                          value={item?.bodyText}
                          classes="mt-3 font-semibold text-center md:text-left"
                        />

                        <div className="flex justify-center md:justify-start mt-4">
                          <div>
                            <select
                              className="text-sm border p-2 rounded-lg w-14"
                              name="date"
                              onChange={(option) => setD(option.target.value)}
                            >
                              {dateArray?.map((i, index) => (
                                <option
                                  key={index}
                                  value={i.value}
                                  selected={
                                    i.value ===
                                    Number(moment(item?.answer).format("DD"))
                                  }
                                >
                                  {i.value}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <select
                              className="text-sm border p-2 rounded-lg w-14 ml-3"
                              name="month"
                              onChange={(option) => setM(option.target.value)}
                            >
                              {monthArray?.map((i, index) => (
                                <option
                                  key={index}
                                  value={i.value}
                                  selected={
                                    i.value ===
                                    Number(moment(item?.answer).format("MM"))
                                  }
                                >
                                  {i.value}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <select
                              className="text-sm border p-2 rounded-lg w-20 ml-3"
                              name="year"
                              onChange={(option) => setY(option.target.value)}
                            >
                              {yearArray?.map((i, index) => (
                                <option
                                  key={index}
                                  value={i.value}
                                  selected={
                                    i.value ===
                                    Number(moment(item?.answer).format("yyyy"))
                                  }
                                >
                                  {i.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="md:mt-4 mt-8 w-full" key={index}>
                      <div className="mb-auto">
                        <div className="flex justify-center md:justify-start">
                          <ImgTag src={Logo} classes="w-5 h-5 mr-2 my-auto" />
                          <DiscriptionTag
                            value={"Question " + (index + 1)}
                            classes="text-slate-600"
                          />
                        </div>
                        <TitleTag
                          value={item?.bodyText}
                          classes="mt-3 font-semibold text-center md:text-left"
                        />
                        <div className="flex justify-center md:justify-start mt-4">
                          <div className="flex flex-col justify-center md:justify-start">
                            {item?.options?.map((i, i2) => (
                              <div className="mt-1" key={i2}>
                                <input
                                  checked={i === item?.answer}
                                  className="my-auto mr-2"
                                  type="radio"
                                  id={item?.questionId + i2}
                                  name={item?.questionId}
                                  value={i}
                                  onChange={(e) => {
                                    handleChange(e, index);
                                  }}
                                />

                                <label
                                  className="font-thin text-lg"
                                  htmlFor={item?.questionId + i2}
                                >
                                  {i}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-full py-12 text-start">
            <span className="text-slate-600 font-normal text-md sm:text-lg md:text-xl">
              Your profile information is incomplete. Please click on the
              <span
                className="text-blue-600 font-semibold text-md sm:text-lg md:text-xl cursor-pointer"
                onClick={() => navigate("/dashboard/home")}
              >
                {` Home `}
              </span>
              tab and answer all the questions.
            </span>
          </div>
        ))}
    </>
  );
};

export default ProfileMenu;
