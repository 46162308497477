import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";

function App() {
  const { user } = useAuth0();

  return (
    <BrowserRouter>
      <AppRoutes email_verified={user?.email_verified} />
      <ToastContainer position="bottom-right" />
    </BrowserRouter>
  );
}

export default App;
