import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeLayout from "../../../layouts/defaultLayout/HomeLayout";
import UploadImage1 from "./UploadImage1";

const UploadImage = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index exact path="/" element={<UploadImage1 />} />
      </Route>

    </Routes>

  );
};

export default UploadImage;
