import { loadStripe } from "@stripe/stripe-js";

export async function checkout({ lineItems, length }) {
  let stripePromise = null;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    }
    return stripePromise;
  };

  const stripe = await getStripe();

  await stripe.redirectToCheckout({
    mode: "payment",
    lineItems,
    successUrl: `${window.location.origin}/dashboard/upload-image?success=true&length=${length}`,
    cancelUrl: window.location.origin,
  });
}
