import React from "react";
import { Navigate } from "react-router-dom";
import { IKV } from "../utils/constants";
import Cookies from "js-cookie";

const ProtectedRoute = () => {
  const isAuthenticated = Cookies.get(IKV);
  return isAuthenticated ? null : <Navigate to="/" />;
};

export default ProtectedRoute;
