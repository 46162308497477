import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeLayout from "../../../layouts/defaultLayout/HomeLayout";
import ContactMenu from "./ContactMenu";

const Contact = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index path="/" element={<ContactMenu />} />
      </Route>
    </Routes>
  );
};

export default Contact;
