import React from "react";
import { Link } from "react-router-dom";
import { FooterLogoBg } from "../../assets/img";
import { ImgTag } from "../../components/design-components/MicroComponents";

const Footer = () => {
  return (
    <div className="hidden sm:block">
      <div className="flex justify-between fixed object-bottom object-cover left-0 bottom-0 w-full h-full sm:max-h-[80px] max-h-[50px]">
        <span className="bg-gradient-to-b from-cyan-500 to-blue-500 absolute bottom-0 left-0 right-0 h-full w-full"></span>
        <div className="flex justify-between text-white my-auto z-30 w-full">
          <div className="ml-12 my-auto hidden sm:block">
            <ImgTag src={FooterLogoBg} classes="sm:h-10" />
          </div>
          <div className="flex space-x-8 font-bold text-xs my-auto ml-12">
            <Link to="/public/privacy-policy">
              <span className="text-[8px] sm:text-xs">Privacy Policy</span>
            </Link>
            <Link to="/public/terms-condition">
              <span className="text-[8px] sm:text-xs">Terms & Conditions</span>
            </Link>
          </div>
          <div className="font-light text-[8px] sm:text-xs my-auto mr-12">
            © {new Date().getFullYear()} iKey All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
