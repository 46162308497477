import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeLayout from "../../../layouts/defaultLayout/HomeLayout";
import HomeMain from "./HomeMain";
import ThankYou from "./ThankYou";
import WelCome from "./WelCome";

const Home = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index exact path="/" element={<WelCome />} />
        <Route exact path="/home" element={<HomeMain />} />
        <Route exact path="/thank-you" element={<ThankYou />} />
      </Route>
    </Routes>
  );
};

export default Home;
