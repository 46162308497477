import axios from "axios";
import { IKV } from "../utils/constants";
import Cookies from "js-cookie";

const axiosApi = axios.create({
  baseURL: `https://${process.env.REACT_APP_DOMAIN}`,
});
const ikv = Cookies.get(IKV) ? `${Cookies.get(IKV)}` : '';
axiosApi.defaults.headers.common["Authorization"] = `Bearer ${atob(ikv)}`;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // const statusCode = error?.response?.status;

    // if (statusCode === 401) {
    //   window.location.href = "/login";
    // }

    return Promise.reject(error);
  }
);

export { axiosApi };
