import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import EyeDashboard from "../../components/common/EyeDashboard";
import Footer from "./Footer";
import Header from "./Header";

export default function DefaultLayout(props) {
  const location = useLocation();

  return (
    <div className="h-screen">
      {location.pathname === "/dashboard" ||
      location.pathname.includes("thank-you") ||
      location.pathname.includes("contact") ? (
        <EyeDashboard />
      ) : (
        ""
      )}
      <Header />
      <div className="absolute top-0 left-0 h-screen w-full pb-[70px] sm:pb-[170px] pt-[55px] sm:pt-[70px] md:pt-[100px] lg:pt-[120px] xl:pt-[150px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
