import React, { useEffect, useState } from "react";
import { Logo } from "../../../assets/img";
import {
  DiscriptionTag,
  ImgTag,
  TitleTag
} from "../../../components/design-components/MicroComponents";

const QuestionBirth = (props) => {
  const { continueClick, data, index } = props;
  const [date, setD] = useState(1);
  const [month, setM] = useState(1);
  const [year, setY] = useState(1960);
  const [dateArray, setDate] = useState();
  const [monthArray, setMonth] = useState();
  const [yearArray, setYear] = useState();

  const [question, setQuestion] = useState();
  const click = () => {
    continueClick({
      question: index + 1,
      data: {
        id: data?.id,
        answer: `${year}-${Number(month).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}-${Number(date).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}T00:00:00.000`,
      },
    });
  };

  useEffect(() => {
    setQuestion(data?.bodyText);
  }, [data]);

  useEffect(() => {
    let dummyArray = [];
    for (let index = 1; index <= 31; index++) {
      dummyArray.push({ value: index });
    }
    setDate(dummyArray);
    let dummyArray1 = [];
    for (let index = 1; index <= 12; index++) {
      dummyArray1.push({ value: index });
    }
    setMonth(dummyArray1);
    let dummyArray2 = [];
    var currentYear = new Date().getFullYear();
    let startYear = 2019 - 20;
    startYear = 1900;
    while (startYear <= currentYear) {
      dummyArray2.push({ value: startYear++ });
    }
    setYear(dummyArray2);
  }, []);

  return (
    // <Formik
    //   initialValues={{
    //     date: "",
    //     month: "",
    //     year: "",
    //   }}
    //   validationSchema={Yup.object().shape({
    //     date: Yup.string().required("Date"),
    //     month: Yup.string().required("Month"),
    //     year: Yup.string().required("Year"),
    //   })}
    //   onSubmit={(fields) => {
    //     continueClick(2);
    //     // navigate("/dashboard/home2");
    //     // alert("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 4));
    //   }}
    //   render={({
    //     errors,
    //     status,
    //     touched,
    //     values,
    //     handleChange,
    //     setFieldValue,
    //   }) => (
    <div className="h-full flex md:justify-start justify-center mt-12 md:mt-0">
      <div className="h-full">
        <div className="h-full mt-auto md:mb-[-38px] ">
          <div className="mb-auto">
            <div className="flex justify-center md:justify-start">
              <ImgTag src={Logo} classes="w-5 h-5 mr-2 my-auto" />
              <DiscriptionTag
                value={"Question " + (index + 1)}
                classes="text-slate-600"
              />
            </div>
            <TitleTag
              value={question}
              classes="mt-3 font-semibold text-center md:text-left"
            />
            <div className="flex flex-col justify-center">
              <div className="flex mt-4 self-center md:self-start">
                <select
                  className="text-sm border p-2 rounded-lg w-14"
                  name="date"
                  onChange={(option) => setD(option.target.value)}
                >
                  {dateArray?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>

                <select
                  className="text-sm border p-2 rounded-lg w-14 ml-3"
                  name="month"
                  onChange={(option) => setM(option.target.value)}
                >
                  {monthArray?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>

                <select
                  className="text-sm border p-2 rounded-lg w-20 ml-3"
                  name="year"
                  onChange={(option) => setY(option.target.value)}
                >
                  {yearArray?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-10 self-center md:self-start">
                <button
                  onClick={click}
                  type="submit"
                  className="px-16 py-2 text-sm font-semibold text-center text-white border rounded-3xl mx-auto bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   )}
    // />
  );
};

export default QuestionBirth;
