import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavBar = () => {
  const location = useLocation();

  const textCSS =
    location.pathname === "/dashboard" ||
    location.pathname.includes("thank-you") ||
    location.pathname.includes("contact")
      ? "text-slate-600 sm:text-white"
      : "text-slate-600";
  const textCSS1 =
    location.pathname === "/dashboard" ||
    location.pathname.includes("thank-you") ||
    location.pathname.includes("contact")
      ? "text-slate-600 sm:text-white"
      : "text-slate-500";
  const borderCSS =
    location.pathname === "/dashboard" ||
    location.pathname.includes("thank-you") ||
    location.pathname.includes("contact")
      ? "border-slate-600 sm:border-white"
      : "border-slate-600";

  return (
    <div className="w-full pb-2 sm:pb-4">
      {location.pathname === "/dashboard" ? (
        <p className={`${textCSS} font-bold text-xl sm:text-2xl md:text-3xl`}>
          Welcome
        </p>
      ) : location.pathname.includes("upload-image") ||
        location.pathname.includes("thank-you") ? (
        <span className="flex">
          <p className={`${textCSS} font-bold text-xl sm:text-2xl md:text-3xl`}>
            Upload
          </p>
          <p
            className={`${textCSS} font-medium text-xl sm:text-2xl md:text-3xl ml-2`}
          >
            images
          </p>
        </span>
      ) : location.pathname.includes("profile") ||
        location.pathname.includes("home") ? (
        <span className="flex">
          <p className={`${textCSS} font-bold text-xl sm:text-2xl md:text-3xl`}>
            Profile
          </p>
          <p
            className={`${textCSS} font-medium text-xl sm:text-2xl md:text-3xl ml-2`}
          >
            Questions
          </p>
        </span>
      ) : location.pathname.includes("why-ikey") ? (
        <span className="flex">
          <p className={`${textCSS} font-bold text-xl sm:text-2xl md:text-3xl`}>
            Why iKey
          </p>
        </span>
      ) : location.pathname.includes("contact") ? (
        <span className="flex">
          <p className={`${textCSS} font-bold text-xl sm:text-2xl md:text-3xl`}>
            Contact us
          </p>
        </span>
      ) : location.pathname.includes("privacy-policy") ? (
        <span className="flex">
          <p className={`${textCSS} font-bold text-xl sm:text-2xl md:text-3xl`}>
            Privacy Policy
          </p>
        </span>
      ) : (
        <span className="flex">
          <p className={`${textCSS} font-bold text-xl sm:text-2xl md:text-3xl`}>
            Terms & Conditions
          </p>
        </span>
      )}

      <div className="mx-auto mt-2">
        <ul className="flex justify-content-center">
          <li>
            <Link
              to="/dashboard"
              className={
                `font-semibold text-[10px] sm:text-sm md:text-md mr-4 ` +
                (location.pathname.includes("home") ||
                location.pathname === "/dashboard"
                  ? `border-b-2 ${borderCSS} ${textCSS}`
                  : `${textCSS1}`)
              }
            >
              Home
            </Link>
          </li>

          <li>
            <Link
              to="/dashboard/upload-image"
              className={
                `font-semibold text-[10px] sm:text-sm md:text-md mr-4 ` +
                (location.pathname.includes("upload-image")
                  ? `border-b-2 ${borderCSS} ${textCSS}`
                  : `${textCSS1}`)
              }
            >
              Upload images
            </Link>
          </li>

          <li>
            <Link
              to="/dashboard/profile"
              className={
                `font-semibold text-[10px] sm:text-sm md:text-md mr-4 ` +
                (location.pathname.includes("profile")
                  ? `border-b-2 ${borderCSS} ${textCSS}`
                  : `${textCSS1}`)
              }
            >
              Profile
            </Link>
          </li>

          <li>
            <Link
              to="/dashboard/why-ikey"
              className={
                `font-semibold text-[10px] sm:text-sm md:text-md mr-4 ` +
                (location.pathname.includes("why-ikey")
                  ? `border-b-2 ${borderCSS} ${textCSS}`
                  : `${textCSS1}`)
              }
            >
              Why iKey®
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/contact"
              className={
                `font-semibold text-[10px] sm:text-sm md:text-md mr-4 ` +
                (location.pathname.includes("contact")
                  ? `border-b-2 ${borderCSS} ${textCSS}`
                  : `${textCSS1}`)
              }
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
