import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckListImage } from "../../../assets/img";
import { ImgTag } from "../../../components/design-components/MicroComponents";
import LoaderSmall from "../../../components/loaderSmall";
import personStore from "../../../stores/person";
import Question1 from "./Question1";
import QuestionBirth from "./QuestionBirth";
import Question2 from "./Question2";

const HomeMain = () => {
  const [activeQue, setActiveQue] = useState(0);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { getAllQuestionApi, getQuestionListData, putAnswersApi } = personStore(
    (state) => ({
      getAllQuestionApi: state.getAllQuestionApi,
      getQuestionListData: state.getQuestionListData,
      putAnswersApi: state.putAnswersApi,
    })
  );
  const continueClick = ({ question, data }) => {
    setLoader(true);
    putAnswersApi(data).then((res) => {
      setLoader(false);
      if (res.status === 200) {
        // if (question === 5 && data?.answer === "No") {
        //   navigate("/dashboard/thank-you");
        // } else {
        if (question === getQuestionListData?.length) {
          navigate("/dashboard/thank-you");
        } else {
          setActiveQue(question);
        }
        // }
      }
      if (res?.response?.status === 409) {
        toast.error(res?.response?.data.message);
      }
    });
  };

  const clickOnBack = () => {
    setActiveQue(activeQue - 1);
  };

  useEffect(() => {
    setLoader(true);
    getAllQuestionApi().then((res) => setLoader(false));
  }, []);

  return (
    <div className="w-full h-full pt-6 pb-24 md:relative">
      {loader && <LoaderSmall />}

      {activeQue !== 0 && (
        <button
          onClick={clickOnBack}
          className="px-3 py-2 mb-5 my-auto text-sm font-semibold text-center text-black border p-2 rounded-3xl w-20 mx-auto"
        >
          {"< Back"}
        </button>
      )}

      <div className="md:flex w-full h-full no-scrollbar overflow-auto">
        <div className="md:basis-2/3 xl:basis-2/3 w-full mb-auto hidden md:block">
          <div className="flex">
            <ImgTag src={CheckListImage} classes="md:h-[200px] lg:h-[360px]" />
          </div>
        </div>
        <div className="md:basis-1/3 xl:basis-2/3 md:ml-10 w-full md:h-full">
          {getQuestionListData?.[activeQue]?.id.includes("date-of-birth") ? (
            <QuestionBirth
              continueClick={continueClick}
              data={getQuestionListData?.[activeQue]}
              index={activeQue}
            />
          ) : Number(activeQue) % 2 === 0 ? (
            <Question1
              continueClick={continueClick}
              data={getQuestionListData?.[activeQue]}
              index={activeQue}
            />
          ) : (
            <Question2
              continueClick={continueClick}
              data={getQuestionListData?.[activeQue]}
              index={activeQue}
            />
          )}
        </div>
      </div>

      {/* <div className="mt-12 md:absolute md:inset-x-0 md:bottom-0">
        <button className="px-4 py-2 mb-10 my-auto text-sm font-semibold text-center text-white border p-2 rounded-3xl w-56 mx-auto bg-gradient-to-b from-[#1AC4F0] to-[#244E95]">
          Previous
        </button>
      </div> */}
    </div>
  );
};

export default HomeMain;
