import React from "react";
import { HeaderBg, WhiteLogo } from "../../assets/img";
import { ImgTag } from "../../components/design-components/MicroComponents";

const Header = () => {
  return (
    <div className="fixed left-0 top-0 right-0">
      <ImgTag
        src={HeaderBg}
        classes="relative w-full h-full max-h-[220px] object-bottom object-cover"
      />
      <ImgTag
        src={WhiteLogo}
        classes="absolute top-0 right-0 lg:h-20 lg:m-10 md:h-16 md:m-8 sm:h-12 sm:m-6 h-8 m-4"
      />
    </div>
  );
};

export default Header;
