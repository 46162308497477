import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RightLogo, WelComeMobileImage } from "../../../assets/img";
import {
  DiscriptionTag,
  ImgTag,
} from "../../../components/design-components/MicroComponents";
import LoaderSmall from "../../../components/loaderSmall";
import personStore from "../../../stores/person";
import { IKV, IKXC } from "../../../utils/constants";

const WelCome = () => {
  const { user } = useAuth0();

  const navigate = useNavigate();
  const clickOnStart = () => {
    navigate("/dashboard/home");
  };
  const { logout } = useAuth0();
  const { findPersonApi, getXCodeAPI, getPerson, addPersonApi } = personStore(
    (state) => ({
      loading: state.loading,
      findPersonApi: state.findPersonApi,
      getXCodeAPI: state.getXCodeAPI,
      getPerson: state.getPerson,
      addPersonApi: state.addPersonApi,
    })
  );

  const [visible, setVisible] = useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (getPerson?.questionnaire?.length > 0) {
      getPerson?.questionnaire?.[getPerson?.questionnaire?.length - 1]
        ?.answer !== null
        ? setVisible(false)
        : setVisible(true);
    }
  }, [getPerson?.questionnaire]);

  const clickOnLogout = () => {
    localStorage.clear();
    Cookies.remove(IKV);
    Cookies.remove(IKXC);
    logout({
      returnTo: `${process.env.REACT_APP_HOST}/signup`,
    });
  };

  useEffect(() => {
    if (user) {
      let formdata = new FormData();
      formdata.append("email", user?.email);
      formdata.append("family_name", user?.family_name);
      formdata.append("given_name", user?.given_name);
      formdata.append("user_id", user?.sub);
      formdata.append("auth0Code", user?.sub?.replaceAll("auth0|", ""));
      addPersonApi(formdata);
      setLoader(true);
      getXCodeAPI().then((res) =>
        findPersonApi().then((res) => setLoader(false))
      );
    }
  }, [user]);

  return (
    <div className="w-full h-full flex">
      {loader && <LoaderSmall />}
      <p className="lg:basis-1/2 hidden lg:block"></p>
      <div className="lg:basis-1/2 m-auto w-full">
        <div className="lg:px-12">
          <ImgTag src={RightLogo} classes="mx-auto h-16 block sm:hidden mb-4" />

          <p className="text-black sm:text-white font-bold text-lg sm:text-2xl md:text-4xl text-center sm:text-left">
            Welcome
          </p>
          <ImgTag
            src={WelComeMobileImage}
            classes="mx-auto h-40 block sm:hidden mb-4"
          />
          <DiscriptionTag
            value="Welcome to iKey® a revolutionary change detection system designed to alert to 
changes happening on your optic nerve. iKey® enables you to record & store images
of your eyes over your lifetime which can be used for your sight-wellness; including 
screening, telemedicine or specialist eye review. Before we start, we need to ask you
a few important questions."
            classes="text-xs sm:text-sm md:text-lg text-black sm:text-white mt-2 text-center sm:text-left"
          />

          <div className="block sm:flex">
            {visible && (
              <button
                className="my-10 block text-sm mx-auto sm:mx-0 font-semibold text-center text-white p-3 rounded-3xl w-72 bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
                onClick={clickOnStart}
              >
                Lets get started
              </button>
            )}
            <div className={`${visible ? "sm:ml-4" : ""}`}>
              <button
                className="my-10 block text-sm mx-auto sm:mx-0 font-semibold text-center text-white p-3 rounded-3xl w-72 bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
                onClick={clickOnLogout}
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelCome;
