import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { FormLogo, Logo } from "../../assets/img";
import { ImgTag } from "../../components/design-components/MicroComponents";
import SignUp from "./SignUp";

const Auth = () => {
  const [activeTab, setActiveTab] = useState(1);
  const { loginWithRedirect } = useAuth0();


  return (
    <div className="md:basis-2/3 lg:basis-1/2 xl:basis-1.5/3 2xl:basis-1/3 m-auto px-5 sm:px-20 md:px-24">
      <div className="flex flex-col bg-slate-50 rounded-lg sm:px-12 py-4">
        <div className="w-full h-full flex flex-col">
          <ImgTag src={FormLogo} classes="h-28 w-28 my-6 mx-auto" />

          <div className="mx-auto">
            <ul className="flex justify-content-center">
              <ImgTag src={Logo} classes="mr-4" />
              <li
                style={{ cursor: "pointer" }}
                className={
                  "font-semibold text-xl p-1 mr-4 " +
                  (activeTab === 1
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500")
                }
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                Sign Up
              </li>

              <li
                className={
                  "font-semibold text-xl p-1 mr-4 " +
                  (activeTab === 2
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500")
                }
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveTab(2);
                }}
              >
                Log-in
              </li>
            </ul>
          </div>

          {activeTab === 1 ? <SignUp /> : loginWithRedirect()}
        </div>
      </div>
    </div>
  );
};

export default Auth;
