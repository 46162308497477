import { Route, Routes } from "react-router-dom";
import Contact from "./Contact";
import Home from "./Home";
import Profile from "./Profile";
import UploadImage from "./UploadImage";
import WhyIKey from "./WhyIKey";

const DashboardRoute = () => {
  return (
    <>
      <Routes>
        <Route index path="/*" element={<Home />} />
        <Route path="/upload-image/*" element={<UploadImage />} />
        <Route path="/why-ikey/*" element={<WhyIKey />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/contact/*" element={<Contact />} />
      </Routes>
    </>
  );
};

export default DashboardRoute;
