import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FooterLogoBg } from "../../assets/img";
import EyeImageBg from "../../components/common/EyeImageBg";
import {
  ImgTag,
  PTag,
} from "../../components/design-components/MicroComponents";
import LoaderSmall from "../../components/loaderSmall";
import { IKV } from "../../utils/constants";
import Footer from "../defaultLayout/Footer";
import AuthHeaderBg from "./AuthHeaderBg";
import Cookies from "js-cookie";

export default function AuthLayout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    location.pathname === "/" &&
      user?.email_verified === true &&
      Cookies.get(IKV) &&
      navigate("/dashboard");

    location.pathname === "/" &&
      user?.email_verified === false &&
      Cookies.get(IKV) &&
      navigate("/signup");

    location.pathname === "/" &&
      !user?.email_verified &&
      Cookies.get(IKV) &&
      navigate("/signup");

    location.pathname === "/" && !Cookies.get(IKV) && navigate("/signup");
    setLoader(false);
  }, [user]);

  return (
    <div className="h-screen">
      {loader && <LoaderSmall />}
      <EyeImageBg />
      <AuthHeaderBg />
      <div className="absolute top-0 left-0 h-screen w-full pb-[80px] no-scrollbar overflow-auto">
        <div className="w-full h-full">
          {!loader && (
            <div className="sm:flex h-full">
              <div className="md:basis-1/3 lg:basis-1/2 xl:basis-2/3 2xl:basis-2/3 flex items-end mb-[70px] sm:mb-[140px] ml-20">
                <div className="hidden sm:block">
                  <ImgTag src={FooterLogoBg} classes="lg:h-14 h-10" />
                  <PTag
                    value="Introducing A Revolutionary New Way To Detect Changes In Health
Based On Changes In The Eye."
                    classes="text-white lg:text-2xl text-md font-thin break-normal lg:w-96 w-52"
                  />
                </div>
              </div>
              {/* <div className="md:basis-2/3 lg:basis-1/2 xl:basis-1.5/3 2xl:basis-1/3 m-auto px-5 sm:px-20 md:px-24">
                <div className="flex flex-col bg-slate-50 rounded-lg sm:px-12 py-4"> */}
              <Outlet />
              {/* </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
