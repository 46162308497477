import axios from "axios";
import { IKV, IKXC } from "../utils/constants";
import Cookies from "js-cookie";

const axiosApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

const ikv = Cookies.get(IKV) ? `${Cookies.get(IKV)}` : ""; // here, access token store as a IKV for security purpose.
const ikxc = Cookies.get(IKXC) ? `${Cookies.get(IKXC)}` : "";
axiosApi.defaults.headers.common["Authorization"] = `Bearer ${atob(ikv)}`;
axiosApi.defaults.headers.common["x-auth-code"] = `${atob(ikxc)}`;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // const statusCode = error?.response?.status;

    // if (statusCode === 401) {
    //   window.location.href = "/login";
    // }

    return Promise.reject(error);
  }
);

export { axiosApi };
