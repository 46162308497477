import { Route, Routes } from "react-router-dom";
import Auth from "./Auth";
import ConfirmEmail from "./ConfirmEmail";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const AuthRoute = ({ email_verified }) => {
  return (
    <Routes>
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route
        exact
        path="/confirm-email"
        element={<ConfirmEmail email_verified={email_verified} />}
      />
      <Route exact path="/signup" element={<Auth />} />
    </Routes>
  );
};

export default AuthRoute;
