import { toast } from "react-toastify";
import create from "zustand";
import { persist } from "zustand/middleware";
import { axiosApi } from "../helpers/axiosTDB";
import { IKV, IKXC } from "../utils/constants";
import Cookies from "js-cookie";

const personStore = create(
  persist(
    (set, get) => ({
      getXCodeData: {},
      getQuestionListData: {},
      getPerson: {},
      addPersonData: {},
      addContactData: {},
      loading: false,
      error: null,
      getXCodeAPI: async (payload) => {
        try {
          set({ loading: true, getXCodeData: {}, error: null });
          const response = await axiosApi.get("/Profile/x-auth-code", null);
          axiosApi.defaults.headers.common["x-auth-code"] = response.data;
          Cookies.set(IKXC, btoa(response.data), {
            secure: true,
          });
          set({ loading: false, getXCodeData: response.data, error: null });
          return response;
        } catch (error) {
          set({
            loading: false,
            getXCodeData: {},
            error: error.response.data.message,
          });
          return error.response.data.message;
        }
      },
      getAllQuestionApi: async (payload) => {
        try {
          set({ loading: true, getQuestionListData: {}, error: null });
          const response = await axiosApi.get(
            "https://tdb.ikey.ie/api/Questions/",
            payload
          );
          set({
            loading: false,
            getQuestionListData: response.data,
            error: null,
          });
        } catch (error) {
          set({
            loading: false,
            getQuestionListData: {},
            error: error.response.data.message,
          });
        }
      },
      putAnswersApi: async (payload) => {
        try {
          const response = await axiosApi.put(
            `/Questions/Answers/${payload?.id}`,
            JSON.stringify(payload?.answer),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response;
        } catch (error) {
          return error;
        }
      },
      findPersonApi: async (payload) => {
        try {
          set({ loading: true, error: null });
          const response = await axiosApi.get("/Profile", payload);
          set({ loading: false, getPerson: response.data, error: null });
          return response;
        } catch (error) {
          set({
            loading: false,
            getPerson: {},
            error: error.response.data.message,
          });
          return error.response.data.message;
        }
      },
      addPersonApi: async (payload, navigate) => {
        try {
          set({ loading: true, addPersonData: {}, error: null });
          const response = await axiosApi.put("/Profile", payload, {
            headers: {
              Authorization: `Bearer ${Cookies.get(IKV)}`,
            },
          });
          set({ loading: false, addPersonData: response.data, error: null });
        } catch (error) {
          set({
            loading: false,
            addPersonData: {},
            error: error.response.data.message,
          });
        }
      },
      addContactApi: async (payload, navigate) => {
        try {
          set({ loading: true, addContactData: {}, error: null });
          const response = await axiosApi.put("/Contact", payload, {
            headers: {
              Authorization: `Bearer ${Cookies.get(IKV)}`,
            },
          });
          set({ loading: false, addContactData: response.data, error: null });
          toast.success("Thank you, we will be in touch");
        } catch (error) {
          set({
            loading: false,
            addContactData: {},
            error: error.response.data.message,
          });
        }
      },
    }),
    { name: "person-storage", getStorage: () => sessionStorage }
  )
);

export default personStore;
