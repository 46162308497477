import React from "react";
import { EyeImage } from "../../assets/img";
import { ImgTag } from "../../components/design-components/MicroComponents";

const EyeImageBg = () => {
  return (
    <ImgTag
      src={EyeImage}
      classes="h-screen w-full object-cover relative hidden sm:block"
    />
  );
};

export default EyeImageBg;
