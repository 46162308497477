import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FormLogo } from "../../assets/img";
import {
  DiscriptionTag,
  ImgTag,
  InputTag,
  TitleTag,
} from "../../components/design-components/MicroComponents";

const ResetPassword = () => {
  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "New Passwords must match")
          .required("Confirm Password is required"),
      })}
      onSubmit={(fields) => {
        // navigate("/auth/signup");
        alert("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 4));
      }}
      render={({ errors, status, touched }) => (
        <Form className="w-full h-full flex flex-col">
          <ImgTag src={FormLogo} classes="h-28 w-28 my-12 mx-auto" />
          <TitleTag value="Reset Your Password" classes="mx-auto font-medium" />
          <DiscriptionTag
            value="To reset your password, please insert your new password and confirm. Thank you."
            classes="mx-auto mt-6 text-slate-400 font-light break-normal w-64 text-center"
          />
          <div className="my-16 mx-auto">
            <div>
              <Field
                as={InputTag}
                name="newPassword"
                type="password"
                placeholder="New Password"
                classes={"text-sm border py-1.5 px-2 rounded w-64 shadow-sm"}
              />
              <ErrorMessage
                name="newPassword"
                component="div"
                className="text-red-500 text-xs"
              />
            </div>
            <div className="mt-3">
              <Field
                as={InputTag}
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                classes={"text-sm border py-1.5 px-2 rounded w-64 shadow-sm"}
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-red-500 text-xs"
              />
            </div>
          </div>

          <div className="form-group">
            <button
              type="submit"
              className="px-4 py-2 mb-10 my-auto block text-sm font-semibold text-center text-white border p-2 rounded-3xl w-56 mx-auto bg-gradient-to-b from-[#1AC4F0] to-[#244E95]"
              placeholder="6 Digit Access Code Here"
            >
              Reset Password
            </button>
          </div>
        </Form>
      )}
    />
  );
};

export default ResetPassword;
