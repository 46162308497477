import React from "react";
import { IKV } from "../../utils/constants";
import NavBar from "../Dashboard/NavBar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const clickOnBack = () => {
    navigate(-1);
  };
  return (
    <div className="w-full h-full sm:px-16 px-8">
      {Cookies.get(IKV) && <NavBar />}
      <button
        onClick={clickOnBack}
        className="px-3 py-2 my-auto text-sm font-semibold text-center text-black border p-2 rounded-3xl w-20 mx-auto"
      >
        {"< Back"}
      </button>

      <div className="w-full h-full flex justify-center">
        <div className="flex flex-col">
          <p className="mb-3 font-medium mx-auto my-4 md:my-6 text-lg sm:text-xl md:text-2xl">
            Privacy Policy
          </p>
          <p className="mx-auto font-thin text-gray-500 text-start no-scrollbar overflow-auto p-2">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-17c7d756 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="17c7d756"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container font-semibold">
                iKey® PRIVACY POLICY{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7be67c51 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="7be67c51"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                  <p>
                  This privacy policy includes important information about your personal data and we encourage you to read it carefully.
                  </p>{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-17c7d756 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="17c7d756"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container font-semibold">
                Your Private Retinal Image:{" "}
                </div>
              </div>
              <div
                class="elementor-element elementor-element-7be67c51 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="7be67c51"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container  mt-2">
                <p>
                  iKey® is a unique biometric capable of identifying the owner of a retinal photograph by the image alone. For that reason, the iKey® company has specialised in developing the service to uniquely protect your uploaded retinal photograph from ever being identified. When you sign-up to use iKey®, you generate your own password attached to your own email address, without which no one can login to your iKey® account.                  
                </p>{" "}
                <p>
                  iKey is uniquely constructed to allow only the user to identify their own retinal photograph. The user’s image is uploaded and stored, with unidentifiable profile information, in a completely separate cache to their iKey® account’s email address and personal password.
                  Only the user, or someone the user has trusted their password with, can login at any time after sign-up and look at retinal photos knowing that they are users’.    
                  No one in the iKey® company or in any company attached to the development and maintenance of iKey® WebApp, can ever identify the owner of an uploaded iKey® image without the owner using  their own unique password
                </p>{" "}     

                <p>
                You may choose in the future to use additional iKey services with different privacy options, including granting a separate commercial entity, for example a company offering iKey services, or a specific eye expert, or a Health Insurance Provider, permission to upload your retinal photos on your behalf in to their own unique iKey® service account for their own input. This will be by informed implied consent between you and them and at no stage will it be possible for anyone within the iKey® company to access your personal account images, specifically using images you have uploaded in to the current service anonymously.
                </p>{" "}  

                <p>
                This policy also serves as a warning to store your iKey® password carefully so that you retain lifelong access to your stored images for lifelong change analysis and sight protection.
                </p>{" "} 


                <p>
                <u>Photo Acquisition:</u>
                The iKey® company is not and cannot be responsible for how you acquire, receive and store your retinal photographs OR for the anonymity of your retinal photographs prior to your uploading them in to the iKey® service. The iKey® company will never know the identity of photographs stored in their service. 
                </p>{" "}                 
                <p>
                It is the policy of the iKey® company to encourage you to request your retinal photographer/or the person who delivers your retinal photograph to you from the retinal camera  to delete your photo from their camera/pc records unless you expressly give them permission to store your retinal photographs for any other reason. 
                </p>{" "}                
                <p>
                Furthermore, the iKey® company cannot identify uploaded photographs in a user’s account and delete them/alter them unless the user supplies the company with the user’s  own unique private password. The iKey® company reserves the right to charge a fee for requests for assistance with correction/replacement/alteration of photos uploaded by the user, either erroneously or on purpose, in to the user’s private iKey® account. 
                </p>{" "} 
                <div
                class="elementor-element elementor-element-17c7d756 de_scroll_animation_no elementor-widget elementor-widget-text-editor"
                data-id="17c7d756"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div class="elementor-widget-container font-semibold">
                Stripe Payment Privacy Policy:{" "}
                </div>
              </div>
                <p>
                
                If you use the Stripe facility to upload a new set of non-baseline retinal photographs to the iKey® service, your payment information with be party to Stripe Privacy Policy www.stripe.com .
                Your payment information  will never be able to be used to identify your retinal photographs in your iKey® account.  
                </p>{" "} 


                </div>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
