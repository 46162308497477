import { Buffer } from "buffer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BaseLineBg,
  CloseButton,
  IKeyProcessing,
  RightImage,
  SmilyIcon,
  TensionEmogy, UploadImageBg
} from "../../../assets/img";
import { ImgTag } from "../../../components/design-components/MicroComponents";
import LoaderSmall from "../../../components/loaderSmall";
import collectionStore from "../../../stores/collection";
import personStore from "../../../stores/person";
import { checkout } from "../Home/checkout";
import MyDropzone from "./MyDropzone";

const UploadImage1 = () => {
  const [rightEye, setRightEye] = useState("");
  const [leftEye, setLeftEye] = useState("");
  const [baselineImageLeft, setBaselineImageLeft] = useState("");
  const [baselineImageRight, setBaselineImageRight] = useState("");
  const [baselineDate, setBaselineDate] = useState("");
  const [baseline, setBaseLine] = useState(true);
  const [showImageProcessing, setShowImageProcessing] = useState(false);
  const [yesChangeDetection, setYesChangeDetection] = useState(false);
  const [noChangeDetection, setNoChangeDetection] = useState(false);
  const [imageIdentialModal, setImageIdentialModal] = useState(false);
  const [selectedImageDisplayModal, setSelectedImageDisplayModal] =
    useState(false);
  const [paymentDoneModal, setPaymentDoneModal] = useState(false);
  const [detectionMessage, setDetectionMessage] = useState("");
  const [displayArray, setDisplayArray] = useState([]);
  const [imageArray, setImageArray] = useState([]);

  const [getImageBufferRightEye, setImageBufferRightEye] = useState(null);
  const [getImageBufferLeftEye, setImageBufferLeftEye] = useState(null);

  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploadSuccessModal, setUploadSuccessModal] = useState(false);
  const [uploadErrorModal, setUploadErrorModal] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const query = new URLSearchParams(window.location.search);

  var img = document.getElementById("imageid");
  //or however you get a handle to the IMG
  var width = img?.clientWidth;
  var height = img?.clientHeight;

  const { findPersonApi, getPerson, loading } = personStore((state) => ({
    findPersonApi: state.findPersonApi,
    getPerson: state.getPerson,
    loading: state.loading,
  }));

  const success = query.get("success");

  useEffect(() => {
    success === "true" && setPaymentDoneModal(true);
  }, [success]);

  const {
    putCollectionApi,
    removeCollectionData,
    getLeftBaseLineImageApi,
    getRightBaseLineImageApi,
    ImageComparisonsAPI,
  } = collectionStore((state) => ({
    putCollectionApi: state.putCollectionApi,
    removeCollectionData: state.removeCollectionData,
    getLeftBaseLineImageApi: state.getLeftBaseLineImageApi,
    getRightBaseLineImageApi: state.getRightBaseLineImageApi,
    ImageComparisonsAPI: state.ImageComparisonsAPI,
  }));

  const clickOnEye = () => {
    if (!success && imageArray?.length !== 0 && imageArray[0]?.isBaseline) {
      setModal(true);
    }
  };

  const uploadSingleImage = () => {
    setLoader(true);
    let formdata = new FormData();
    leftEye && formdata.append("LeftEye", leftEye);
    rightEye && formdata.append("RightEye", rightEye);
    if (firstTime === true) {
      setBaseLine(true);
      setFirstTime(false);
    }
    if (firstTime === false) {
      setBaseLine(false);
    }
    putCollectionApi(formdata).then((res) => {
      if (res.status === 200) {
        baseline === true && setShowModal(true);
        if (firstTime === false) {
          setShowModal(false);
          findPersonApi().then((res1) => {
            res1.status === 200 && clickOnPressIkey(res1?.data);
          });
        }
      }
      setLoader(false);
      if (res === "Image could not be uploaded") {
        setUploadErrorModal(true);
      }
    });
  };

  const clickOnUpload = () => {
    if (imageArray?.length === 0) {
      if (rightEye && leftEye) {
        setLoader(true);
        let formdata = new FormData();
        formdata.append("LeftEye", leftEye);
        formdata.append("RightEye", rightEye);
        if (firstTime === true) {
          setBaseLine(true);
          setFirstTime(false);
        }
        if (firstTime === false) {
          setBaseLine(false);
        }
        putCollectionApi(formdata).then((res) => {
          if (res.status === 200) {
            baseline === true && setShowModal(true);
            if (firstTime === false) {
              setShowModal(false);
              findPersonApi().then((res1) => {
                res1.status === 200 && clickOnPressIkey(res1?.data);
              });
            }
          }
          setLoader(false);
          if (res === "Image could not be uploaded") {
            setUploadErrorModal(true);
          }
        });
      } else if (!rightEye && !leftEye) {
        toast.error("First select eye images.");
      } else {
        setSelectedImageDisplayModal(true);
      }
    } else if (
      !success &&
      imageArray?.length !== 0 &&
      imageArray[0]?.isBaseline
    ) {
      setModal(true);
    } else {
      if (success && imageArray?.length !== 0 && imageArray[0]?.isBaseline) {
        if (rightEye && leftEye) {
          setLoader(true);
          let formdata = new FormData();
          formdata.append("LeftEye", leftEye);
          formdata.append("RightEye", rightEye);
          if (firstTime === true) {
            setBaseLine(true);
            setFirstTime(false);
          }
          if (firstTime === false) {
            setBaseLine(false);
          }
          putCollectionApi(formdata).then((res) => {
            if (res.status === 200) {
              baseline === true && setShowModal(true);
              if (firstTime === false) {
                // setDisplayArray([]);
                // setImageArray([]);
                setShowModal(false);
                findPersonApi().then((res1) => {
                  res1.status === 200 && clickOnPressIkey(res1?.data);
                });
              }
            }
            setLoader(false);
            if (res === "Image could not be uploaded") {
              setUploadErrorModal(true);
            }
          });
        } else if (!rightEye && !leftEye) {
          toast.error("First select eye images.");
        } else {
          setSelectedImageDisplayModal(true);
          // toast.error("First select both eye images.");
        }
      }
    }
  };

  useEffect(() => {
    findPersonApi();
  }, [findPersonApi]);

  useEffect(() => {
    let array = [];
    let array1 = [];

    (async () => {
      for (const items of getPerson?.imagePairCollection?.slice(0)?.reverse() ||
        []) {
        const responseRightEye = items?.rightImageSha256
          ? await getRightBaseLineImageApi(items.rightImageSha256).then((res) =>
              res?.data
                ? "data:image/png;base64," +
                  Buffer.from(res?.data, "binary").toString("base64")
                : ""
            )
          : "";
        const responseLeftEye = items?.leftImageSha256
          ? await getLeftBaseLineImageApi(items.leftImageSha256).then((res) =>
              res?.data
                ? "data:image/png;base64," +
                  Buffer.from(res?.data, "binary").toString("base64")
                : ""
            )
          : "";

        if (items?.isBaseline === false) {
          array.push(responseRightEye || "");
          array1.push(responseLeftEye || "");
          setImageBufferRightEye(array);
          setImageBufferLeftEye(array1);
        }
      }
    })();

    setImageArray(getPerson?.imagePairCollection);
    let a = [];
    a.push(...getPerson?.imagePairCollection);
    a?.splice(0, 1);
    setDisplayArray(a);
  }, [getPerson]);

  useEffect(() => {
    if (imageArray?.length > 0) {
      setFirstTime(false);
      setLoader(true);
      setBaselineDate(imageArray[0]?.uploadedOn);
      imageArray[0]?.leftImageSha256 &&
        getLeftBaseLineImageApi(imageArray[0]?.leftImageSha256).then((res) => {
          if (res.status === 200) {
            setBaselineImageLeft(
              "data:image/png;base64," +
                Buffer.from(res?.data, "binary").toString("base64")
            );
          }
        });
      imageArray[0]?.rightImageSha256 &&
        getRightBaseLineImageApi(imageArray[0]?.rightImageSha256).then(
          (res) => {
            setLoader(false);
            if (res.status === 200) {
              setBaselineImageRight(
                "data:image/png;base64," +
                  Buffer.from(res?.data, "binary").toString("base64")
              );
            }
          }
        );
    }
  }, [imageArray]);

  const clickOnPressIkey = (data) => {
    setUploadSuccessModal(false);
    setLoader(false);
    setShowImageProcessing(true);
    ImageComparisonsAPI(
      data?.imagePairCollection[data?.imagePairCollection?.length - 1]?.id
    ).then((res) => {
      setShowImageProcessing(false);
      if (res.status === 200) {
        setImageIdentialModal(true);
        setDetectionMessage(res);
      }
    });
  };

  const savedModalClose = () => {
    setLoader(true);
    findPersonApi();
    setRightEye(null);
    setLeftEye(null);
    setShowModal(false);
    removeCollectionData();
  };

  const savedModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={savedModalClose}
        ></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-md p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="mt-3">
              <div className="w-10 h-8 ml-auto" onClick={savedModalClose}>
                <div className="cursor-pointer">
                  <ImgTag src={CloseButton} />
                </div>
              </div>

              <div className="flex justify-center">
                <ImgTag src={RightImage} classes="h-16 sm:h-24" />
              </div>
              <div className="mt-2 text-center">
                <h4 className="text-lg sm:text-2xl font-medium text-gray-800">
                  Baseline Images Saved!
                </h4>
                <div className="mt-2 flex justify-center">
                  <p className="text-xs sm:text-sm text-gray-500 w-72">
                    {`Congratulations, you have successfully uploaded your
                    baseline images on ${moment(new Date()).format(
                      "DD MMM, yyyy"
                    )}`}
                  </p>
                </div>
                <div className="flex justify-center">
                  <button
                    className="mt-2 py-2.5 px-12 text-white bg-[#1AC4F0] rounded-3xl"
                    onClick={savedModalClose}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const selectedImageModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
        ></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-md p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="mt-3">
              
              <div className="mt-2 text-center">
                <h4 className="text-lg sm:text-2xl font-medium text-gray-800">
                  Uplaod Image!
                </h4>
                <div className="mt-2 flex justify-center">
                  <p className="text-xs sm:text-sm text-gray-500 w-72 my-4">
                    {`You only selected one image to upload, are you sure you wish to upload only one image?`}
                  </p>
                </div>
                <div className="flex justify-center">
                  <button
                    className="mt-2 py-2.5 px-6 text-white bg-[#1AC4F0] rounded-3xl"
                    onClick={() => {
                      setSelectedImageDisplayModal(false);
                      uploadSingleImage();
                    }}
                  >
                    Continue
                  </button>
                  <button
                    className="ml-3 mt-2 py-2.5 px-6 text-white bg-[#1AC4F0] rounded-3xl"
                    onClick={() => setSelectedImageDisplayModal(false)}
                  >
                    Select second image
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const successModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => clickOnPressIkey()}
        />
        <div
          className="flex items-center min-h-screen px-4 py-8 cursor-pointer"
          onClick={() => clickOnPressIkey()}
        >
          <div className="relative w-full max-w-md mx-auto">
            <div className="flex justify-center mt-6">
              <div className="rounded-xl w-[250px] h-20 sm:w-[300px] sm:h-24 md:w-[400px] md:h-32 px-2 py-3 sm:px-4 md:py-5 md:px-8 bg-[#60A946] flex justify-between">
                <div className="my-auto mr-3 md:mr-6">
                  
                  <p className="font-medium text-white text-sm sm:text-md md:text-lg">
                    {`You have ${
                      imageArray?.length + 1
                    } sets of images witch can be analysed`}
                  </p>
                  
                </div>
                <ImgTag src={SmilyIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const errorModalClose = () => {
    setRightEye(null);
    setLeftEye(null);
    setUploadErrorModal(false);
    removeCollectionData();
  };

  const errorModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={errorModalClose}
        ></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-md mx-auto">
            {/* Non Eye image */}
            <div className="flex justify-center mt-6">
              <div className="rounded-xl w-[250px] h-20 sm:w-[300px] sm:h-24 md:w-[400px] md:h-32 px-4 py-3 sm:px-8 md:py-5 md:px-12 bg-[#EFFF36] flex justify-between">
                <div className="my-auto mr-3 md:mr-6">
                  <p className="font-medium text-black text-sm sm:text-md md:text-lg">
                    Non Eye image
                  </p>
                  <p className="font-thin text-black text-xs sm:text-md md:text-lg">
                    Please upload again
                  </p>
                </div>
                <ImgTag src={TensionEmogy} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const payment = () => {
    checkout({
      lineItems: [
        {
          price: process.env.REACT_APP_PRICE_ID,
          quantity: 1,
        },
      ],
      length: imageArray?.length + 1,
    });
  };

  const paymentModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-md p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="">
              <div className="w-10 h-8 ml-auto" onClick={() => setModal(false)}>
                <div className="cursor-pointer">
                  <ImgTag src={CloseButton} />
                </div>
              </div>

              <div className="text-center">
                <h4 className="text-lg sm:text-2xl font-medium text-gray-800">
                  Upload
                </h4>
                <div className="mt-2 flex justify-center">
                  <p className="text-xs sm:text-sm text-gray-500 w-72">
                    {`Payment must be processed please before next image upload`}
                  </p>
                </div>
                <div className="flex justify-center">
                  <button
                    className="mt-2 py-2.5 px-12 text-white bg-[#1AC4F0] rounded-3xl"
                    onClick={() => {
                      setModal(false);
                      payment();
                    }}
                  >
                    Make Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const paymentM = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-md p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="">
              <div
                className="w-10 h-8 ml-auto"
                onClick={() => setPaymentDoneModal(false)}
              >
                <div className="cursor-pointer">
                  <ImgTag src={CloseButton} />
                </div>
              </div>

              <div className="text-center">
                <h4 className="text-lg sm:text-2xl font-medium text-gray-800">
                  Payment
                </h4>
                <div className="mt-2 flex justify-center">
                  <p className="text-xs sm:text-sm text-gray-500 w-72">
                    {`Your payment is successful, please upload`}
                  </p>
                </div>
                <div className="flex justify-center">
                  <button
                    className="mt-2 py-2.5 px-12 text-white bg-[#60A946] rounded-3xl"
                    onClick={() => {
                      setPaymentDoneModal(false);
                    }}
                  >
                    Thank you
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const noChangeModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => {
            setNoChangeDetection(false);
            navigate("/dashboard");
          }}
        ></div>
        <div
          className="flex items-center min-h-screen px-4 py-8 cursor-pointer"
          onClick={() => {
            setNoChangeDetection(false);
            navigate("/dashboard");
          }}
        >
          <div className="flex justify-center items-center w-full mt-6">
            {/* Change detection no */}
            <div className="relative w-full">
              <div className="flex justify-center">
                <div className="max-w-[650px] w-full bg-[#19487A] rounded-3xl py-3 px-6 sm:py-6 sm:px-14 md:py-10 md:px-20">
                  <div className="flex justify-between">
                    <div className="my-auto">
                      <p className="font-extrabold text-white text-sm sm:text-xl md:text-2xl">
                        Change detection
                      </p>

                      <p className="font-thin items-end text-white text-[10px] sm:text-sm md:text-md w-60 sm:w-72 md:w-80">
                        iKey® will alert to most changes on your optic nerve
                        pattern. If concerned that other changes which are not
                        detectable by iKey are present, please consult your
                        optician or eye specialist for a full eye examination.
                      </p>
                    </div>
                    <div className="w-16 h-16 sm:w-24 sm:h-24 md:w-28 md:h-28 flex justify-center items-center rounded-full my-auto bg-white text-black font-extrabold text-lg sm:text-2xl md:text-3xl">
                      No
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const yesChangeModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => {
            setYesChangeDetection(false);
            navigate("/dashboard");
          }}
        ></div>
        <div
          className="flex items-center min-h-screen px-4 py-8 cursor-pointer"
          onClick={() => {
            setYesChangeDetection(false);
            navigate("/dashboard");
          }}
        >
          <div className="flex justify-center items-center w-full mt-6">
            {/* Change detection yes */}
            <div className="relative w-full">
              <div className="flex justify-center">
                <div className="max-w-[650px] w-full bg-red-500 rounded-3xl py-3 px-6 sm:py-6 sm:px-14 md:py-10 md:px-20">
                  <div className="flex justify-between">
                    <div className="my-auto">
                      <p className="font-extrabold text-black text-sm sm:text-xl md:text-2xl">
                        Change detection
                      </p>

                      <p className="font-thin items-end text-black text-[10px] sm:text-sm md:text-md w-60 sm:w-72 md:w-80">
                        There is chance you could have a silent eye condition
                        which might need treatment. Consider a full eye
                        examination with your optician or eye specialist.
                      </p>
                    </div>
                    <div className="w-16 h-16 sm:w-24 sm:h-24 md:w-28 md:h-28 flex justify-center items-center rounded-full my-auto bg-white text-black font-extrabold text-lg sm:text-2xl md:text-3xl">
                      Yes
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const imageModal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-md p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="">
              <div
                className="w-10 h-8 ml-auto"
                onClick={() => {
                  setImageIdentialModal(false);
                  navigate("/dashboard");
                }}
              >
                <div className="cursor-pointer">
                  <ImgTag src={CloseButton} />
                </div>
              </div>

              <div className="text-center">
                <h4 className="text-lg sm:text-2xl font-medium text-gray-800">
                  Image processing
                </h4>
                <div className="mt-2 flex justify-center">
                  <p className="text-xs sm:text-sm text-gray-500 w-72">
                    {detectionMessage?.data?.left?.message?.errors?.[0] ===
                    "Images are idential" ? (
                      <>
                        <p>
                          {"Right : " +
                            (detectionMessage?.data?.right?.message
                              ?.errors?.[0] || "-")}
                        </p>
                        <p>
                          {"Left : " +
                            (detectionMessage?.data?.left?.message
                              ?.errors?.[0] || "-")}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          {"Right : " +
                            (detectionMessage?.data?.right?.message
                              ?.textMessage || "-")}
                        </p>
                        <p>
                          {"Left : " +
                            (detectionMessage?.data?.left?.message
                              ?.textMessage || "-")}
                        </p>
                      </>
                    )}
                  </p>
                </div>
                <div className="flex justify-center">
                  <button
                    className="mt-3 py-2.5 px-12 text-white bg-[#1AC4F0] rounded-3xl"
                    onClick={() => {
                      setImageIdentialModal(false);
                      navigate("/dashboard");
                    }}
                  >
                    Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="w-full h-full overflow-auto px-2">
      {loader && <LoaderSmall />}
      {loading && <LoaderSmall />}
      {uploadSuccessModal && successModal}
      {uploadErrorModal && errorModal}
      {showModal && savedModal}
      {modal && paymentModal}
      {paymentDoneModal && paymentM}
      {noChangeDetection && noChangeModal}
      {yesChangeDetection && yesChangeModal}
      {imageIdentialModal && imageModal}
      {selectedImageDisplayModal && selectedImageModal}

      {!loading &&
        (getPerson?.questionnaire?.[getPerson?.questionnaire?.length - 1]
          ?.answer === null ? (
          <div className="w-full h-full py-12 text-start xl:pl-40 lg:pl-28 sm:pl-16 pl-4">
            <span className="text-slate-600 font-normal text-md sm:text-lg md:text-xl">
              Your profile information is incomplete. Please click on the
              <span
                className="text-blue-600 font-semibold text-md sm:text-lg md:text-xl cursor-pointer"
                onClick={() => navigate("/dashboard/home")}
              >
                {` Home `}
              </span>
              tab and answer all the questions.
            </span>
          </div>
        ) : (
          <>
            {/* IKey Image Processing */}

            {showImageProcessing && (
              <div className="flex justify-center mt-6">
                <div className="relative">
                  <ImgTag src={IKeyProcessing} id="imageid" />
                  <div
                    style={{
                      width: `${width}px`,
                      height: `${height}px`,
                      top: "0",
                      left: "0",
                      bottom: "0",
                      right: "0",
                    }}
                    className="absolute"
                  >
                    <div className="flex h-full justify-center items-center">
                      <div className="w-full rounded-xl h-full overflow-hidden bg-gray-700 opacity-25 flex flex-col items-center justify-center z-[100]">
                        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8 mb-4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!showImageProcessing && !imageIdentialModal && (
              <div className="flex justify-center mt-6">
                <div className="relative">
                  <ImgTag src={UploadImageBg} />
                  <div className="absolute flex flex-col justify-center items-center top-0 w-full h-full p-1 sm:p-2 md:p-4">
                    <p className="text-center font-bold text-lg sm:text-xl lg:text-2xl text-white">
                      Upload Your Image
                    </p>

                    <div className="w-full text-center my-auto">
                      <div className="flex justify-center">
                        <MyDropzone
                          success={success}
                          imageArray={imageArray}
                          setRightEye={setRightEye}
                          rightEye={rightEye}
                          clickOnEye={clickOnEye}
                          id="rightEye"
                          label="Right Eye"
                        />
                        
                        <MyDropzone
                          success={success}
                          imageArray={imageArray}
                          setRightEye={setLeftEye}
                          rightEye={leftEye}
                          clickOnEye={clickOnEye}
                          id="leftEye"
                          label="Left Eye"
                        />

                      </div>
                    </div>
                    <button
                      onClick={clickOnUpload}
                      className="md:px-4 md:py-1.5 sm:px-2 sm:py-1 px-1 py-0.5 text-xs md:text-sm font-semibold text-center text-black border rounded-3xl w-40 md:w-64 mx-auto bg-white inline-block"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            )}

            {displayArray && (
              <GetAllEyeImageComp
                displayArray={displayArray?.slice(0)?.reverse()}
                getImageBufferLeftEye={getImageBufferLeftEye}
                getImageBufferRightEye={getImageBufferRightEye}
              />
            )}

            {/* Box 4 */}
            <div className="flex justify-center mt-6">
              <div className="relative">
                <ImgTag src={BaseLineBg} />
                <div className="absolute flex flex-col justify-center items-center top-0 w-full h-full p-1 sm:p-2 md:p-4">
                  <p className="text-center font-bold text-md sm:text-2xl lg:text-3xl text-white">
                    Baseline images
                  </p>
                  <div className="w-full text-center my-auto">
                    <div className="flex justify-center">
                      <div className="py-1 px-2 sm:py-2 sm:px-5 md:py-4 md:px-10 mr-8">
                        <p className="font-medium text-white text-[10px] sm:text-md md:text-lg">
                          Right Eye
                        </p>

                        <div className="sm:mt-1 mt-0.5">
                          <div className="border-dashed border-2 w-24 h-24 sm:w-40 sm:h-40">
                            <ImgTag src={baselineImageRight} classes="p-2" />
                          </div>
                        </div>
                        <label className="md:px-2 md:py-2 sm:px-1 sm:py-1 px-0.5 py-0.5 text-[6px] sm:text-[8px] md:text-[10px] sm:mt-1 mt-0.5 font-thin text-center text-black border rounded w-24 sm:w-36 md:w-40 mx-auto bg-white inline-block">
                          {"Upload date " +
                            (baselineDate
                              ? moment(baselineDate).format("DD/MM/yyyy")
                              : "")}
                        </label>
                      </div>

                      <div className="py-1 px-2 sm:py-2 sm:px-5 md:py-4 md:px-10">
                        <p className="font-medium text-white text-[10px] sm:text-md md:text-lg">
                          Left Eye
                        </p>

                        <div className="sm:mt-1 mt-0.5">
                          <div className="border-dashed border-2 w-24 h-24 sm:w-40 sm:h-40">
                            <ImgTag src={baselineImageLeft} classes="p-2" />
                          </div>
                        </div>
                        <label className="md:px-2 md:py-2 sm:px-1 sm:py-1 px-0.5 py-0.5 text-[6px] sm:text-[8px] md:text-[10px] sm:mt-1 mt-0.5 font-thin text-center text-black border rounded w-24 sm:w-36 md:w-40 mx-auto bg-white inline-block">
                          {"Upload date " +
                            (baselineDate
                              ? moment(baselineDate).format("DD/MM/yyyy")
                              : "-")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

const GetAllEyeImageComp = ({
  displayArray,
  getImageBufferRightEye,
  getImageBufferLeftEye,
}) => {
  return (
    <div>
      {(() => {
        const arr = [];
        let index = 0;
        for (const item of displayArray) {
          arr.push(
            <div className="flex justify-center mt-6">
              <div className="relative">
                <ImgTag src={BaseLineBg} />
                <div className="absolute flex flex-col justify-center items-center top-0 w-full h-full p-1 sm:p-2 md:p-4">
                  <div className="w-full text-center my-auto">
                    <div className="flex justify-center">
                      <div className="py-1 px-2 sm:py-2 sm:px-5 md:py-4 md:px-10 mr-8">
                        <p className="font-medium text-white text-[10px] sm:text-md md:text-lg">
                          Right Eye
                        </p>
                        <div className="sm:mt-1 mt-0.5">
                          <div className="border-dashed border-2 w-24 h-24 sm:w-40 sm:h-40">
                            <ImgTag
                              src={getImageBufferRightEye?.[index]}
                              classes="p-2"
                            />
                          </div>
                        </div>
                        <label className="md:px-2 md:py-2 sm:px-1 sm:py-1 px-0.5 py-0.5 text-[6px] sm:text-[8px] md:text-[10px] sm:mt-1 mt-0.5 font-thin text-center text-black border rounded w-24 sm:w-36 md:w-40 mx-auto bg-white inline-block">
                          {"Upload date " +
                            (item?.uploadedOn
                              ? `${moment(item?.uploadedOn).format(
                                  "DD/MM/yyyy"
                                )}`
                              : "-")}
                        </label>
                      </div>
                      <div className="py-1 px-2 sm:py-2 sm:px-5 md:py-4 md:px-10">
                        <p className="font-medium text-white text-[10px] sm:text-md md:text-lg">
                          Left Eye
                        </p>
                        <div className="sm:mt-1 mt-0.5">
                          <div className="border-dashed border-2 w-24 h-24 sm:w-40 sm:h-40">
                            <ImgTag
                              src={getImageBufferLeftEye?.[index]}
                              classes="p-2"
                            />
                          </div>
                        </div>
                        <label className="md:px-2 md:py-2 sm:px-1 sm:py-1 px-0.5 py-0.5 text-[6px] sm:text-[8px] md:text-[10px] sm:mt-1 mt-0.5 font-thin text-center text-black border rounded w-24 sm:w-36 md:w-40 mx-auto bg-white inline-block">
                          {"Upload date " +
                            (item?.uploadedOn
                              ? `${moment(item?.uploadedOn).format(
                                  "DD/MM/yyyy"
                                )}`
                              : "-")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          index++;
        }

        return arr;
      })()}
    </div>
  );
};

export default UploadImage1;
