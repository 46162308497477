import React from "react";
import { HeaderBg } from "../../assets/img";
import { ImgTag } from "../../components/design-components/MicroComponents";

const AuthHeaderBg = () => {
  return (
    <div className="fixed left-0 top-0 right-0">
      <ImgTag
        src={HeaderBg}
        classes="w-full h-full max-h-[220px] object-bottom object-cover"
      />
    </div>
  );
};

export default AuthHeaderBg;
