import { toast } from "react-toastify";
import create from "zustand";
import { persist } from "zustand/middleware";
import { axiosApi } from "../helpers/axios";

const authStore = create(
  persist(
    (set, get) => ({
      signupData: {},
      getTokenData: {},
      loading: false,
      error: null,

      signUpApi: async (payload, navigate) => {
        try {
          set({ loading: true, signupData: {}, error: null });
          const response = await axiosApi.post(
            "/dbconnections/signup",
            payload
          );
          toast.success("Sign-up successful");
          set({ loading: false, signupData: response.data, error: null });
          navigate("/confirm-email");
        } catch (error) {
          set({
            loading: false,
            signupData: {},
            error: error.response.data.description,
          });
          toast.error(
            error?.response?.data?.message || error?.response?.data?.description
          );
        }
      },
      getTokenApi: async (payload, navigate) => {
        try {
          set({ loading: true, getTokenData: {}, error: null });
          const response = await axiosApi.post("/oauth/token", payload);
          set({ loading: false, getTokenData: response.data, error: null });
          toast.success("Log-in successfully.");
          navigate("/confirm-email");
        } catch (error) {
          set({
            loading: false,
            getTokenData: {},
            error: error.response.data.message,
          });
        }
      },
    }),
    { name: "auth-storage", getStorage: () => "" }
  )
);

export default authStore;
