import React from "react";
import { WelsomeEyeImage, WhyIKeyImage } from "../../../assets/img";
import { ImgTag } from "../../../components/design-components/MicroComponents";

const IKeyIntro = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-full h-full no-scrollbar overflow-auto">
        <div className="lg:flex w-full">
          <div className="lg:basis-1/2 w-full xl:h-full my-auto">
            <div className="w-full xl:h-[40rem] my-auto">
              <p className="mx-auto flex justify-center items-center lg:text-xl 2xl:text-2xl font-thin text-gray-500 w-72 md:w-[36rem] lg:text-right text-center h-full">
                How many of us know we have the potential to lose our sight? How
                many of us know that while we continue to see perfectly, areas
                of our sight (our visual field) are being stolen, silently, by
                treatable conditions such as Glaucoma? How many of us really
                care, when our eyes do what we need them to do every day. Until
                they don’t!
              </p>
            </div>
          </div>
          <div className="lg:basis-1/2 w-full xl:h-[40rem] lg:ml-10 my-auto">
            <div className="w-full xl:h-[40rem] my-auto">
              <ImgTag src={WhyIKeyImage} classes="mx-auto w-full" />
            </div>
          </div>
        </div>
        <div className="text-center lg:text-xl 2xl:text-2xl mt-5 text-white  py-12 px-24  bg-gradient-to-b from-[#1AC4F0] to-[#244E95]">
          If iKey® detects a change in the unique pattern on your optic nerve,
          it will alert you and advise you to go for an expert eye exam. iKey®
          has a 91% chance of detecting a change in this pattern, one of the
          earliest signs of silent Glaucoma.
        </div>

        <div className="lg:flex w-full mt-8">
          <div className="lg:basis-1/2 w-full xl:h-[35rem] my-auto">
            <div className="w-full xl:h-[35rem] my-auto">
              <ImgTag src={WelsomeEyeImage} classes="mx-auto w-full" />
            </div>
          </div>
          <div className="lg:basis-1/2 w-full xl:h-full my-auto sm:ml-10">
            <div className="w-full h-full my-auto">
              <div className="w-full xl:h-[35rem] my-auto flex flex-col">
                <p className="mx-auto flex justify-center items-center font-bold lg:text-xl 2xl:text-2xl text-black w-72 md:w-[36rem] lg:text-start text-center">
                  How does it work? Here’s the techy bit which we’ve kept as
                  simple as possible.
                </p>
                <p className="mt-4 mx-auto flex justify-center items-center lg:text-lg 2xl:text-xl font-thin text-gray-500 w-72 md:w-[36rem] lg:text-start text-center">
                  Everyone is born with a unique pattern on their optic nerve.
                  Working with fundus eye images, the iKey® patented disruptive
                  A.I. software detects silent sight threatening changes on the
                  optic nerve. iKey® was invented to use the unique pattern on
                  the optic nerve as a biometric and through its analysis can
                  halt the preventable loss of sight as it alerts to changes
                  found on this unique biometric pattern. iKey® can
                  automatically compare any new images in the future against
                  your baseline images to detect your own unique optic nerve
                  pattern.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default IKeyIntro;
